import React from 'react'

const SeeButton = ({listener, style, textBtn, type='button'}) => {
    return (
        <button className='see-Button' type={type} onClick={listener} style={{...style}}>
                {textBtn}
        </button>
    )
}

export default SeeButton
