import React, { useEffect, useRef } from 'react'
import cerrar from '../assets/static/buttons/boton cerrar.png'

const Modal = ({ children, showModal, setShowModal, disabledClose, loading }) => {
    const ModalBgRef = useRef({})

    const hideModal = () => {
        if (!disabledClose) {
            document.body.style.overflow='visible'
            setShowModal(!showModal)
        }
    }
    
    useEffect(() => {
        if (showModal) {
            document.body.style.overflow='hidden'
        }
        const clickEvent=()=>{
            hideModal();
        }
        const keyEsc=(e)=>{
            if (e.key=="Escape") {
                hideModal();
            }
        }
        if (showModal) {
            ModalBgRef.current.addEventListener('click',clickEvent)
            document.addEventListener('keyup', keyEsc)
        }
        return () => {
            if(!showModal){
                ModalBgRef.current.removeEventListener("click", clickEvent)
                document.removeEventListener('keyup',keyEsc)}
                document.body.style.overflow='visible'
        }
    }, [showModal])

    return (
        <div className='modal' hidden={showModal?false:true}>
            <div className={`modal_background${loading?" loadingBg":""}`} ref={ModalBgRef}></div>
            <div className={`modal_container${loading?" loadingCont":""}`}>
                {!disabledClose && <img src={cerrar} className='modal_img_close' onClick={() => hideModal()} />}
                <div className="modal_content">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal