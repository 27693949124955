import React from 'react'
import Modal from './Modal'

const Loading = ({ showLoading }) => {
  return (
    <Modal showModal={showLoading} setShowModal={()=>{}} disabledClose loading>
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </Modal>
  )
}

export default Loading