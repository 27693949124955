import React, { useEffect, useRef, useState } from 'react'
import Edit from '../../assets/static/buttons/editar@2x.png'
import EditActivo from '../../assets/static/buttons/editarwishlistactivo@2x'
import Delete from '../../assets/static/buttons/eliminar@2x.png'
import DeleteActivo from '../../assets/static/buttons/eliminarwishlistactivo@2x.png'
import Cart from '../../assets/static/buttons/movercarrito@2x.png'
import CartActivo from '../../assets/static/buttons/moverwishlistactivo@2x.png'
import currencyFormat from '../utilities/currencyFormat'



const WishItem = ({ product, clickSendToEdit, clickSendToCart, clickDelete }) => {
    const [priceItem, setPriceItem] = useState(0)

    const editRef = useRef({})
    const deleteRef = useRef({})
    const cartRef = useRef({})
    
    useEffect(() => {
        // let photoPrice = product.photo.base_price
        let totalPrice = 0
        for (let property in product){
            let itemPrice = product[property]?.price ? product[property]?.price : 0
            totalPrice += itemPrice
        }
        setPriceItem(totalPrice);
    }, [])

    return (
        <div className="wishItem">
            <img className="wishItem__img" src={product.photo?.file.url} alt={product.photo?.id} />
            <div className="wishItem__info">
                <h3>{product.photo?.name}</h3>
                {product.size?.name && <h4>Tamaño {product.size?.name} </h4>}
                {product.package?.name && <h4>Empaque {product.package?.name}</h4>}
                <div className="wishItem__subMat">
                    {product.frame?.name && <span>Marco - {product.frame?.name} </span>}
                    {product.material?.name && <span>Papel - {product.material?.name} </span>}
                </div>
            </div>
            <div className="wishItem__options">
                <div>
                    <img src={Edit} alt="Editar" onClick={clickSendToEdit}
                        onMouseOver={() => editRef.current.src = EditActivo}
                        onMouseLeave={() => editRef.current.src = Edit} ref={editRef} />
                    <span>Editar</span>
                </div>
                <div>
                    <img src={Delete} alt="Eliminar" onClick={() => clickDelete(product) }
                        onMouseOver={() => deleteRef.current.src = DeleteActivo}
                        onMouseLeave={() => deleteRef.current.src = Delete} ref={deleteRef} />
                    <span>Eliminar</span>
                </div>
                <div>
                    <img src={Cart} alt="Wishlist" onClick={clickSendToCart} 
                        onMouseOver={() => cartRef.current.src = CartActivo}
                        onMouseLeave={() => cartRef.current.src = Cart} ref={cartRef} />
                    <span>Continuar Compra</span>
                </div>
            </div>
        </div>
    )
}
export default WishItem
