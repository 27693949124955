import React from 'react'
import Title from '../Title'
import pregunta from '../../assets/static/icon/pregunta.png'
const FailedPurchase = () => {
  return (

    <div className='ErrorPurchase'>
        <div className='ErrorPurchase__cont'>
            <Title title={' '} backTitle={true} alignLeft={true}/>
        </div>
        <div className="serverError">
            <div className="serverError__cont">
                <img src={pregunta}></img>
                <h1>Compra Fallida</h1>
                <p>Los datos de la cuenta no registran</p>
                <button 
                    type='button' 
                    className='ErrorPurchase__button'
                    onClick={()=>window.location='/cart'}>
                Volver
                </button>
            </div>
        </div>
    </div>
  )
}

export default FailedPurchase