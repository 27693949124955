import React, { useEffect, useRef, useState } from 'react'
import Left from '../assets/static/buttons/left-arrow.png'
import Right from '../assets/static/buttons/right-arrow.png'
import ActionButton from './ActionButton'

export default function Carrousel({ contentCarousel, roomView = false }) {
    const [index, setIndex] = useState(0)
    const [stop, setStop] = useState(false)
    const [viewMobile, setViewMobile] = useState(null)
    const timerChangeBanner = useRef()

    const itemCarousel = contentCarousel[index]

    const alingnInfo = (textAlign) => {
        switch (textAlign) {
            case "left":
                return " info-left"
            case "right":
                return " info-right"
            case "center":
                return " info-center"
            default:
                return " info-center";
        }
    }

    const navigation = (route) => { //route example: /artist 
        window.location = route
    }

    useEffect(() => {
        if (!stop) {
            timerChangeBanner.current = setTimeout(() => {
                next(1)
            }, 5000);
        }
        return () => {if (!stop) clearTimeout(timerChangeBanner.current)}
    }, [index])

    const stopCarousel = () => {
        clearTimeout(timerChangeBanner.current)
        setStop(true)
    }
    
    const next = (dir) => {
        // 0 is left
        // 1 is right
        const direction = [-1, 1]
        let currentBanner = index
        let nextBanner = (currentBanner + direction[dir]) % contentCarousel.length;
        nextBanner = nextBanner < 0 ? (contentCarousel.length - 1) : nextBanner
        setIndex(nextBanner)
    }

    useEffect(() => {
        const updateSize = ()=>{
          let width = window.innerWidth
          if (width >= 720 && viewMobile) {
            setViewMobile(false)
          }else if (width < 720 && !viewMobile) {
            setViewMobile(true)
          } 
        }
        if (viewMobile === null) updateSize()
    
        window.addEventListener('resize', updateSize)
        return () => {
          window.removeEventListener('resize', updateSize)
        }
      })

    return (
        <div className='carousel'>
             {viewMobile && !roomView &&
                <div className={`carousel__topInfo${!itemCarousel?.title?" hideInfoText":""}`}>
                    {contentCarousel.map((item, i)=>
                    <div key={"infoItem"+i} className={`topInfo__cont${index===i?" selectedInfoText":""}`} >
                        {item?.title && <h1>{item?.title}</h1>}
                        {item?.text && <p>{item?.text}</p>}
                        {item?.action_button !== "" &&
                            <ActionButton listener={()=>navigation(item?.action_button)} textBtn={"Ir"}/>
                        }
                    </div>
                    )}
                </div>}
            <div className="carousel__cont">
                <div className='carousel__leftButton' onClick={() => {next(0); stopCarousel()}}>
                    {contentCarousel.length>1?
                    <img src={Left} alt="left"/>:
                    null}
                </div>
                <div className="carousel__banners">
                    {contentCarousel.map((item, i)=>
                        <div className={`carousel__item${index===i?" selectedBanner":""}`} key={"carousel"+i}>
                            <img style={{ height: '100%', width: '100%', objectFit:'cover' }} alt={"banner"+index} src={item?.file.url} />
                            {item?.title && !viewMobile && !roomView &&
                            <div className='carousel__info'>
                                <div className={`info__cont${alingnInfo(item?.text_align)}`}>
                                    {item?.title && <h1>{item?.title}</h1>}
                                    {item?.text && <p>{item?.text}</p>}
                                    {item?.action_button !== "" &&
                                        <ActionButton listener={()=>navigation(item?.action_button)} textBtn={"Ir"}/>
                                    }
                                </div>
                            </div>}
                        </div>
                    )}
                </div>
                <div className='carousel__rigthButton' onClick={() => {next(1); stopCarousel()}}>
                    {contentCarousel.length>1?
                    <img src={Right} alt="right"/>:
                    null}
                    
                </div>
                <div className='carousel__bannerInd'>
                    {contentCarousel.map((_, i) => {
                            return (
                                <div className={`carousel__indicator`} key={`indicator${i}`} id={`indicator${i}`} onClick={()=>{setIndex(i); stopCarousel()}}>
                                    <div className={`carousel__indicatorFilling${i === index ? ' indicatorFilled' : ''}`}></div>
                                </div>
                            )
                        }
                    )}
                </div>
            </div>
        </div>
    )
}
