import React from 'react'
import Title from '../Title'

const SuccessfulPurchase = () => {
  let formaPago="debito red compra PSE davivienda terminada en "
  let npago="1031"
  let referenciaPago="4923121608"
  let codigoAutorizacion="601319"
  let tipoPago="Debito"
  let mensaje 
  return (
  <div className='ErrorPurchase'>
    <div className='ErrorPurchase__cont'>
        <Title title={' '} backTitle={true} alignLeft={true}/>
    </div>
    <div className="SuccessfulPurchase">
        <div className="SuccessfulPurchase__cont">
            <h1>¡Muchas gracias por tu compra! </h1>
            <p>Recibirás tu pedido entre 3 a 10 días podras hacer el seguimiento con el codigo en la factura que te llegara a tu cooreo electronico registrado </p>
            <div className='SuccessfulPurchaseDetail'>
              <div>
                <img src=''/>
                <p>Pago aprobado</p>
              </div><br/>
              <span className='SuccessfulPurchaseDetailPurchase' >Detalle del pago:</span>
              
              <div className='SuccessfulPurchase__detalledepago'>
                <span className='SuccessfulPurchase__formadepago'>{formaPago} 
                <span className='bold'>{npago}</span></span>
                <span className='SuccessfulPurchase__referenciaPago'>Referencia de pago:{referenciaPago}</span>
                <span className='SuccessfulPurchase__codigoAutorizacion'>Codigo de autorizacion{codigoAutorizacion}</span>
                <span className='SuccessfulPurchase__tipoPago'>Tipo de pago:{tipoPago}</span>
              </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default SuccessfulPurchase