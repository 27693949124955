import React, { useEffect, useRef, useState } from 'react'
import Left from '../assets/static/buttons/left-arrow.png'
import Right from '../assets/static/buttons/right-arrow.png'

const CarouselDownMarket = ({ photos, idSelected, listener,accordion,tamaño,srcImg,galeria, wishItems = [] }) => {
    const [currentItem, setCurrentItem] = useState(0)
    const contentRef = useRef({})
    const content = contentRef.current
    const leftRef = useRef({})
    const rightRef = useRef({})

    useEffect(() => {
        const cont = contentRef.current
        const handleWheel = (e) => {
            e.preventDefault()
            if (!accordion) {
                let maxItems = photos.length - 1
                let nextItem = currentItem + Math.round((e.deltaY) * 0.01)
                if (nextItem > maxItems) return 
                else if (nextItem < 0) return
                setCurrentItem(nextItem)
                listener(photos[nextItem])
            }
            cont.scrollLeft += (e.deltaY * 1.3);
        }
        cont.addEventListener('wheel', handleWheel)
        return () => {
            cont.removeEventListener('wheel', handleWheel)
        }
    }, [currentItem])
    
    const handleScroll=()=>{
        let limit = content.scrollWidth
        let scroll = content.scrollLeft
        let offset = content.offsetWidth
        if((scroll+offset)>=(limit-1)){
            rightRef.current.style.visibility = "hidden"
        }else{
            leftRef.current.style.visibility = "visible"
            rightRef.current.style.visibility = "visible"
        }
        if (scroll === 0){
            leftRef.current.style.visibility = "hidden"
        }
    }

    const loadArrows = () => {
        let limit = content.scrollWidth
        let scroll = content.scrollLeft
        let offset = content.offsetWidth
        if (scroll === 0)leftRef.current.style.visibility = "hidden"
        if (limit <= offset) rightRef.current.style.visibility = "hidden"
    }

    const handleClick = (dir)=>{
        let direction = {}
        direction = {
            'left':-100,
            'right':100
        }
        if (dir) {
            contentRef.current.scrollLeft += direction[dir];
        }
    }
    const imageSize = (size) => {
        if (size === "Grande") return 100
        if (size === "Mediano") return 75
        if (size === "Pequeño") return 50
        else return 0
    }

    const handleSelected = (id) => {
        let findSelected = photos.find((item)=>id === item.id)
        let currentPhoto = photos.indexOf(findSelected)
        setCurrentItem(currentPhoto)
        listener(findSelected)
    }

    return (
        <div className="carouselDownMarket__carousel">
            <img className="carouselDownMarket__direction opacidad " src={Left} alt="Left" ref={leftRef} onClick={()=>handleClick('left')}/>
            <div className='carouselDownMarket__content' ref={contentRef} onScroll={(e)=>handleScroll(e)} onLoad={loadArrows}>
                    {photos.map((item,i) =>
                    galeria?
                    <div className= {idSelected === item.id ?  'carouselDownMarket__contentImage--selected': 'carouselDownMarket__contentImage'} key={item.id}>
                        {!!wishItems?.find(wish=>wish?.photo_id === item.id) && <HeartIcon/>}
                        <img src={item.file.url} alt={item.nombre} onClick={()=>handleSelected(item.id)} title={item.nombre} />
                    </div>
                    :
                    tamaño? 
                        <div className= {idSelected === i ?  'carouselDownMarket__cont__tamaño--selected':
                        'carouselDownMarket__cont__tamaño'}                          
                        key={item.id}
                        
                        onClick={()=>handleSelected(item.id)} >
                            <img src={srcImg} 
                            style={{ width:`${imageSize(item.name)}%`}} alt={item.name} title={item.name}/>
                            <span>{item.name}</span>
                            <span>{item.dimensions}</span>
                        </div>
                        :
                        <img className= {idSelected === i ?  'carouselDownMarket__cont_material--selected':
                        'carouselDownMarket__cont_material'} key={item.id} src={item.file.url} alt={item.name} onClick={()=>handleSelected(item.id)} title={item.name}/>
                    )}
            </div>
            <img className="carouselDownMarket__direction" src={Right} alt="Right" ref={rightRef} onClick={()=>handleClick('right')}/>
        </div>
    )
}

const HeartIcon = ({color = "#000000"}) => (
    <div style={{width: "20px", height: "20px", position: "absolute", right: "0"}} title="El elemento se encuentra en la lista de deseos">
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg>
    </div>
)

export default CarouselDownMarket
