import React from 'react'
import SeeButton from '../../SeeButton'

const InternalServerError = () => {
  return (
    <div className="serverError">
      <div className="serverError__container">
        <h1>😵</h1>
        <h2>500</h2>
        <p>Lo sentimos, pero algo salió mal.</p>
      </div>
    </div>
  )
}

export default InternalServerError