import React, { useState } from 'react'
import Title from '../Title'
import UserAccountData from './UserAccountData'
import UserAccountPurchase from './UserAccountPurchase'
import SectionWishlist from './SectionWishlist' 
import user from '../../assets/static/icon/user.png'
import userProfile from '../../assets/static/buttons/perfilusuario@2x.png'
import userProfileActive from '../../assets/static/buttons/perfilusuarioactivobtn@2x.png'
import userCart from '../../assets/static/buttons/perfilcarrito@2x.png'
import userCartActive from '../../assets/static/buttons/perfilcarritoactivobtn@2x.png'
import userWish from '../../assets/static/buttons/perfilwish@2x.png'
import userWishActive from '../../assets/static/buttons/perfilwishactivobtn@2x.png'
import ShoppingCart2 from '../../assets/static/images/ShoppingCart2.png'
import ShoppingCart3 from '../../assets/static/images/ShoppingCart3.png'


const UserAccount = ({currentUser, purchases, failed_purchases, addresses}) => {
    const [accountInfo, setAccountInfo] = useState(1)
    return (
        <div className='userAccount'>
            <Title title='MI CUENTA' />
            <div className='userAccount__info'>
                <img src={user} width='200'  height='200'/>
                <span>{currentUser?.name}</span>
            </div>
            <div className='userAccount__menu'>
                <div onClick={()=>setAccountInfo(1)}>
                    <img src={accountInfo!==1?userProfile:userProfileActive} alt="Mi Perfil"/>
                    <span>Mi cuenta</span>
                </div>
                <div onClick={()=>setAccountInfo(2)}>
                    <img src={accountInfo!==2?userCart:userCartActive} alt="Mi Carrito"/>
                    <span>Mis Compras</span>
                </div>
            </div>
            <div className='userAccount__cont'>
                {accountInfo===1&&
                    <UserAccountData userAccount={currentUser} {...{addresses}}/>}
                {accountInfo===2 && 
                <div className='userAccount__purchase'>
                    <h3 className='detail__purchase' >Compras Satisfactorias</h3> 
                        {purchases.length > 0 && purchases.map((purchase)=>
                            <UserAccountPurchase key={purchase.id} purchase={purchase}/>
                        )}

                    <h3 className='detail__purchase'>Compras No Completadas</h3>
                        {failed_purchases.length > 0 && failed_purchases.map((failed_purchase)=>
                            <UserAccountPurchase key={failed_purchase.id} purchase={failed_purchase}/>
                        )}
                    {purchases.length === 0 && failed_purchases.length === 0 && <h3 className='detail__purchase'>No Tienes compras registradas</h3>}
                </div>}
                
                {accountInfo===3&&
                <div>
                    <SectionWishlist/>
                </div>}
            </div>
        </div>
    )
}

export default UserAccount
