import React from 'react'
import ModalButton from './ModalButton'

const ConfirmModal = ({ acept, cancel }) => {
  return (
    <div className='confirmModal_content'>
      <h3 className='confirmModal_title'>Continuar Compra</h3>
      <div className='confirmModal_info'>
        <span>Item Agregado correctamente. </span>
        <span>¿Desea agregar otro o continuar con la compra?</span>
        <div className='confirmModal_buttons'>
          <ModalButton listener={acept} textBtn="Agregar otro producto" />
          <ModalButton listener={cancel} textBtn="Continuar" />
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal