import React, { useEffect, useState } from 'react'
import galeria from '../../assets/static/buttons/galeria@2x.png'
import galeriaActivo from '../../assets/static/buttons/galeriaactivo@2x.png'
import espacio from '../../assets/static/buttons/entucasa@2x.png'
import espacioActivo from '../../assets/static/buttons/entucasaactivo@2x.png'
import compra from '../../assets/static/buttons/perfilcarrito@2x.png'
import whishList from '../../assets/static/buttons/perfilwish@2x.png'
import imgGalery from '../../assets/static/images/galeria.jpg'
import currencyFormat from '../utilities/currencyFormat'

const ShowProduct = ({ currentUser, photo, setScreen , room, listenerWishList }) => {
    const [sala, setSala] = useState("picture")
    const [horizontal, setHorizontal] = useState(null)
 
    useEffect(() => {
        const imagenCargada = (e) => {
            var width = e.target.naturalWidth;
            var height = e.target.naturalHeight;
            if(width/height>1){
                setHorizontal(true)
            }else{
                setHorizontal(false)
            }
        }

        let pictureGalery = new Image();
        pictureGalery.addEventListener('load', imagenCargada);
        pictureGalery.src = photo.file.url

        return () => {
            pictureGalery.removeEventListener('load', imagenCargada);
        }
        
    }, [photo, sala])

    const horizontalBg = (bool) => {
        switch (bool) {
            case true: return room?.space_horizontal?.url
            case false: return room?.space_vertical?.url
            
            default:
                break;
            }
    }

    const showSpaces = () => {
        setSala('spaces')
    }

    const changeView = () => {
        if(!currentUser) return window.location.href = "/users/sign_in"
        setScreen('purchase')
    }

    return (
        <div className="showProduct" >
            <div className="showProduct__info">
                {photo&&sala==="picture"?
                    <div className="showProduct__picture"><img src={photo.file.url}/></div>
                :sala==="spaces"&&
                <div className="showProduct__spaces">
                    <div>
                        <h3>De la galería...</h3>
                        <div className="showProduct__spacesGaleryBgCont">
                            <img className="showProduct__spacesGaleryBg" src={imgGalery} alt="pikisuperstar"/>
                            <img className={`showProduct__spacesGaleryPt${horizontal?"--horizontal":""}`} src={photo.file.url} alt="image"/>
                        </div>
                    </div>
                    {horizontal !== null && 
                    <div>
                        <h3>...a tus espacios</h3>
                        <div className="showProduct__spacesSpaceBgCont">
                            <img className="showProduct__spacesSpaceBg" src={horizontalBg(horizontal)} alt="espacios"/>
                            <img className={`showProduct__spacesSpacePt${horizontal?"--horizontal":""}`} src={photo.file.url} alt="galeriaPt" style={horizontal ? {top:room?.top_horizontal+"%" || 0, left: room?.left_horizontal+"%" || 0} : {top:room?.top_vertical+"%" || 0, left: room?.left_vertical+"%" || 0}}/>
                        </div>
                    </div>}
                </div>
                }
                {photo?
                <div className="showProduct__text">
                    <span><strong style={{fontWeight:"bold"}}>{photo.name}: </strong> {photo.description}</span>
                    <br></br>
                </div>
                :
                <span> <h3> Galeria vacia </h3> </span>
                }
            </div>
            <div className="showProduct__menu">
                <div onClick={()=>setSala('picture')}>
                    <img src={sala === "picture" ? galeriaActivo : galeria }/>
                    <span>Volver a la foto</span>
                </div>
                <div onClick={()=>showSpaces()}>
                    <img src={sala === "spaces" ? espacioActivo : espacio}/>
                    <span>En tu espacio</span>
                </div>
                <div onClick={()=>listenerWishList("show")}>
                    <img src={whishList}/>
                    <span>Wish List</span>
                </div>
                <div onClick={changeView}>
                    <img src={compra}/>
                    <span>Especificar Diseño</span>
                </div>
            </div>
        </div>
    )
}

export default ShowProduct
