import React, { useEffect, useState } from 'react'
import Wish from '../assets/static/buttons/wish@2x.png'
import User from '../assets/static/buttons/perfil@2x.png'
import Shop from '../assets/static/buttons/carrito@2x.png'
import Search from '../assets/static/buttons/buscar@2x.png'
import Menu from '../assets/static/buttons/menu.png'
import Logo from '../assets/static/graphics/logoartree.png'

const menu = ['¿Quienes somos?', 'EXCLUSIVE', 'PRO', 'Materiales', 'Artistas', 'Wishlist', 'Carrito','Contáctenos']

const Header = ({currentUser, wish, cart}) => {
    // console.log('currentUser-->',currentUser)
    // console.log('cart :>> ', cart);
    const [showMenu, setShowMenu] = useState(false)
    const [notificationWhish, setNotificationWhish] = useState(wish)
    const [notificationCart, setNotificationCart] = useState(cart)
    
    const userName = `${currentUser?.name.split(" ", 1)} ${currentUser?.last_name.split(" ", 1)}`

    const navigate = (section) => {
        const redirect = {
            '¿Quienes somos?': '/about',
            'EXCLUSIVE': '/line/1',
            'PRO': '/line/2',
            'Materiales': '/materials',
            'Artistas': '/artist',
            'Register': '/users/sign_in',
            'Wishlist': '/wishlist',
            'Carrito': '/cart',
            'Usuario': '/profile',
            'Contáctenos': '/contact',
            'home': '/'
        }
        window.location = redirect[section] ? redirect[section] : '/'
    }

    // const search = () => {
    //     let searchItem = prompt("que foto estas buscando?","separa los terminos de busqueda con una coma")
    //     let text;
    //     if (searchItem == null || searchItem == "") {
    //         console.log('busqueda cancelada')
    //     } else {
    //         window.location = '/search?tags='+searchItem
    //     }
    // }

    useEffect(() => {
      const wishHandler = window.addEventListener("wishHandler", (e) => {
        setNotificationWhish(e.detail.wishItems)
      })
      const cartHandler = window.addEventListener("cartHandler", (e) => {
        setNotificationCart(e.detail.cartItems)
      })
      return () => {
        window.removeEventListener("wishHandler", wishHandler)
        window.removeEventListener("cartHandler", cartHandler)
      }
    }, [])
    
    
    useEffect(() => {
        const clickEvent=()=>{
            setShowMenu(!showMenu)
        }
        if (showMenu) {
            document.addEventListener('click',clickEvent)            
        }
        return () => {
            document.removeEventListener("click", clickEvent)
        }
    }, [showMenu])

    return (
        <nav className="header">
            <div className="header__cont">
                <img className="header__logo" src={Logo} alt='Logo' onClick={()=>navigate('home')}/>
                    <div className="header__iconsCont">
                        <div className={!currentUser?"icons__notification":"header__name"} onClick={()=>navigate('Usuario')}>
                            {currentUser?
                                <span className=''>{userName}</span>
                                :
                                <img className='header__icon' src={User}/>
                            }
                        </div>
                        <div className='icons__notification' onClick={()=>navigate('Wishlist')}>
                            <img className='header__icon2' src={Wish} />
                            {notificationWhish!==0&&<div>{notificationWhish}</div>}
                        </div>
                        <div className='icons__notification' onClick={()=>navigate('Carrito')}>
                            <img className='header__icon2' src={Shop} />
                            {notificationCart!==0&&<div>{notificationCart}</div>}
                        </div>
                        {/* <img className='header__icon' src={Search} onClick={()=>{search()}}/> */}
                        <img className="menu__img"src={Menu} alt="Menu" onClick={()=>{setShowMenu(true)}}/>
                    </div>
                    
                    {showMenu&&
                    <div className="menu__content">
                        {menu.map((item,i)=>
                            <div className="menu__item" onClick={()=>navigate(item)} key={"menu"+i}>
                                <h3>{item}</h3>
                            </div>
                        )}
                        {currentUser&&
                            <div className="menu__name">
                                <a data-method="delete" href="/users/sign_out"><h3>Cerrar Sesión</h3></a>
                            </div>}
                    </div>}
            </div>
        </nav>
    )
}

export default Header
