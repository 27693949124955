export const cities = [
  {city: "Bogotá", state: "D. C."},
  {city: "Medellín", state: "Antioquia"},
  {city: "Cali", state: "Valle del Cauca"},
  {city: "Barranquilla", state: "Atlántico"},
  {city: "Bello", state: "Antioquia"},
  {city: "Itagüí", state: "Antioquia"},
  {city: "Envigado", state: "Antioquia"},
  {city: "Rionegro", state: "Antioquia"},
  {city: "Turbo", state: "Antioquia"},
  {city: "Apartadó", state: "Antioquia"},
  {city: "Arauca", state: "Arauca"},
  {city: "Soledad", state: "Atlántico"},
  {city: "Malambo", state: "Atlántico"},
  {city: "Sabanalarga", state: "Atlántico"},
  {city: "Cartagena", state: "Bolívar"},
  {city: "Magangué", state: "Bolívar"},
  {city: "Turbaco", state: "Bolívar"},
  {city: "Tunja", state: "Boyacá"},
  {city: "Sogamoso", state: "Boyacá"},
  {city: "Duitama", state: "Boyacá"},
  {city: "Manizales", state: "Caldas"},
  {city: "Florencia", state: "Caquetá"},
  {city: "Yopal", state: "Casanare"},
  {city: "Popayán", state: "Cauca"},
  {city: "Santander de Quilichao", state: "Cauca"},
  {city: "Valledupar", state: "Cesar"},
  {city: "Aguachica", state: "Cesar"},
  {city: "Quibdó", state: "Chocó"},
  {city: "Montería", state: "Córdoba"},
  {city: "Lorica", state: "Córdoba"},
  {city: "Sahagún", state: "Córdoba"},
  {city: "Cereté", state: "Córdoba"},
  {city: "Soacha", state: "Cundinamarca"},
  {city: "Facatativá", state: "Cundinamarca"},
  {city: "Fusagasugá", state: "Cundinamarca"},
  {city: "Mosquera", state: "Cundinamarca"},
  {city: "Chía", state: "Cundinamarca"},
  {city: "Zipaquirá", state: "Cundinamarca"},
  {city: "Madrid", state: "Cundinamarca"},
  {city: "Girardot", state: "Cundinamarca"},
  {city: "Funza", state: "Cundinamarca"},
  {city: "Riohacha", state: "Guajira"},
  {city: "Uribia", state: "Guajira"},
  {city: "Maicao", state: "Guajira"},
  {city: "San José del Guaviare", state: "Guaviare"},
  {city: "Neiva", state: "Huila"},
  {city: "Pitalito", state: "Huila"},
  {city: "Santa Marta", state: "Magdalena"},
  {city: "Ciénaga", state: "Magdalena"},
  {city: "Villavicencio", state: "Meta"},
  {city: "Pasto", state: "Nariño"},
  {city: "Tumaco", state: "Nariño"},
  {city: "Ipiales", state: "Nariño"},
  {city: "Cúcuta", state: "Norte de Santander"},
  {city: "Ocaña", state: "Norte de Santander"},
  {city: "Villa del Rosario", state: "Norte de Santander"},
  {city: "Mocoa", state: "Putumayo"},
  {city: "Armenia", state: "Quindío"},
  {city: "Pereira", state: "Risaralda"},
  {city: "Dosquebradas", state: "Risaralda"},
  {city: "San Andrés", state: "San Andrés y Providencia"},
  {city: "Bucaramanga", state: "Santander"},
  {city: "Floridablanca", state: "Santander"},
  {city: "Barrancabermeja", state: "Santander"},
  {city: "Piedecuesta", state: "Santander"},
  {city: "Girón", state: "Santander"},
  {city: "Sincelejo", state: "Sucre"},
  {city: "Ibagué", state: "Tolima"},
  {city: "Palmira", state: "Valle del Cauca"},
  {city: "Buenaventura", state: "Valle del Cauca"},
  {city: "Tuluá", state: "Valle del Cauca"},
  {city: "Jamundí", state: "Valle del Cauca"},
  {city: "Cartago", state: "Valle del Cauca"},
  {city: "Buga", state: "Valle del Cauca"},
  {city: "Yumbo", state: "Valle del Cauca"},
  {city: "N/A", state: "No está en la lista"},
]