import React from 'react'
import Title from '../Title'
import img from '../../assets/static/images/print.png'

const text = 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur laboriosam eveniet nobis quas facilis quis quam obcaecati consequatur necessitatibus porro voluptates itaque at perferendis, nulla ducimus corrupti impedit repellendus a.'


const InfoPrintPhotos = ({texts}) => {
    const infoPrint_button=texts.infoPrint_button ;
    const infoPrint_title=texts.infoPrint_title;
    const infoPrint_steps=texts.infoPrint_steps;

 
    const list = infoPrint_steps.split('*')
    return (
        <div>
            <Title title={infoPrint_title} backTitle={true} listener={()=>window.location='/about'}/>
            <div className='infoPrintPhotos'>
                <div className='infoPrintPhotos__cont'>
                    <div className='infoPrintPhotos__img'>
                        <img src={img}/>
                        <button>{infoPrint_button} </button>
                    </div>
                    <div className='infoPrintPhotos__list'>
                        <ol>
                            {list.map((item,i)=>
                            <li key={'item'+i}>{item}</li>)}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoPrintPhotos
