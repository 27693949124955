import React from "react";

const ModalButton = ({ listener, textBtn, type = "button" }) => {
  return (
    <button className="modal_button" type={type} onClick={listener}>
      {textBtn}
    </button>
  );
};

export default ModalButton;
