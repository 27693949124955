import React from "react"
import PurchaseFailed from "./PurchaseFailed"
import PurchaseSuccess from "./PurchaseSuccess"
const result = ["APPROVED"]

const Result = ({currentUser, transaction}) => {
  const s = transaction.status
  // console.log('transaction', transaction)
  return (
    <div>
      {result.includes(s) ?
        <PurchaseSuccess info = {transaction} currentUser = {currentUser}/>
        : <PurchaseFailed info = {transaction} currentUser = {currentUser}/>
      }
    </div>

  );
}

export default Result;