import { object } from "prop-types";
import React, { useRef, useEffect, useState } from "react";
import Edit from "../../assets/static/buttons/editar@2x.png";
import EditActivo from "../../assets/static/buttons/editarwishlistactivo@2x.png";
import Delete from "../../assets/static/buttons/eliminar@2x.png";
import DeleteActivo from "../../assets/static/buttons/eliminarwishlistactivo@2x.png";
import Wishlist from "../../assets/static/buttons/moverwishlistbtn@2x.png";
import WishlistActivo from "../../assets/static/buttons/perfilwishactivobtn@2x.png";
import api from "../api";
import currencyFormat from "../utilities/currencyFormat";


const CartItem = ({ editItem, clickWishList, clickDelete, product, updateAmount }) => {

  const imagePro = product?.line_id === 2 ? true : false
  const stock = product?.photo.stock
  const selectStock = imagePro && stock >= 100 ? 100 : stock
  const [ceroStock, setCeroStock] = useState(null);
  const cant = Array.from({ length: selectStock }, (_, i) => i + 1)
  const editRef = useRef({});
  const deleteRef = useRef({});
  const wishRef = useRef({});
  // const [total,setTotal] = useRef(null);

  useEffect(() => {
    const obj = {}
    obj[product.photo.id] = product.quantity
    
    api.verifyAvailableCart({ ids: obj }, (response) => {
      if (!response.success) {
      setCeroStock("Sin unidades disponibles, por favor eliminalo o evialo a wishlist")
      // console.log('cero  stock');
      
      }
    })
  }, [product])

  const updateQuantity = (value) => {
    let temp = { ...product }
    temp.quantity = parseInt(value)
    updateAmount(temp)
    
  }
  
  return (
    <div className="cardItem">
      <div className="cardItem__product">
      {ceroStock===null?
        <div className="cardItem__img">
          <img src={product.photo.file.url} alt={product.photo.name} />
        </div> 
        :
        <div className="cardItem__img__disabled">
          <img src={product.photo.file.url} alt={product.photo.name} />
        </div>}

        <div className="cardItem__infoAndPrice">
        <div className="cardItem__info">
          <h3>LINEA {product.size.line_id}</h3>
          <h4>{product.photo.name}, </h4>
          <span>{product.size.dimensions}, </span>
          <span>empaque tipo {product.package.name}, </span>
          <span>papel {product.material.name}, </span>
          <span>marco {product.frame.name}.</span>

          <h2>{ceroStock}</h2>
        </div>
        {ceroStock===null &&
        <div className="cardItem__price">
              {product.quantity > 1 && <div className="cardItem__unitPrice">Valor unitario: {currencyFormat(product?.frame.price + product?.material.price + product?.package.price + product?.size.price)}</div>}
            <div className="cardItem__priceInfo">
              <select value={product.quantity} onChange={(e) => updateQuantity(e.target.value,)}>
                {cant.map((number, i) => (
                  <option value={number} key={"option"+i}>{number}</option>
                  ))}
              </select>
              <h3>{currencyFormat((product?.frame.price + product?.material.price + product?.package.price + product?.size.price) * (product.quantity || 1))}</h3>
            </div>
        </div>}
      </div>      
      </div>
      

      <div className="cardItem__options">
              {ceroStock===null?
            <div className="cardItem__options__btn">
                <img
                  src={Edit}
                  alt="Editar"
                  onClick={() => {editItem(product)}}
                  onMouseOver={() => (editRef.current.src = EditActivo)}
                  onMouseLeave={() => (editRef.current.src = Edit)}
                  ref={editRef}
                />
                <span>Editar</span>
            </div>:null}
          
            
            <div className="cardItem__options__btn">
              <img
                src={Delete}
                alt="Eliminar"
                onClick={() => clickDelete(product)}
                onMouseOver={() => (deleteRef.current.src = DeleteActivo)}
                onMouseLeave={() => (deleteRef.current.src = Delete)}
                ref={deleteRef}
              />
              <span>Eliminar</span>
            </div>
            
            <div className="cardItem__options__btn">
              <img
                src={Wishlist}
                alt="wishlist"
                onClick={() => clickWishList(product)}
                onMouseOver={() => (wishRef.current.src = WishlistActivo)}
                onMouseLeave={() => (wishRef.current.src = Wishlist)}
                ref={wishRef}
              />
              <span>Wishlist</span>
            
          </div>
          
          
          
          
        </div>
    </div>
  );
};

export default CartItem;
