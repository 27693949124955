import React from 'react'
import distintion from '../../assets/static/icon/sello.png'
import asesoria from '../../assets/static/icon/asesoria.png'
import compra from '../../assets/static/icon/compra.png'
import garantia from '../../assets/static/icon/garantia.png'
import original from '../../assets/static/icon/original.png'
import talento from '../../assets/static/icon/talento.png'
import left from '../../assets/static/buttons/left-arrow.png'
import right from '../../assets/static/buttons/right-arrow.png'
import client from '../../assets/static/images/cliente.png'
import logoInsta from '../../assets/static/icon/instafooter@2x.png'
import logoFacebook from '../../assets/static/icon/facebookfooter@2x.png'
import logoWhats from '../../assets/static/icon/whatsfooter@2x.png'
import logoPinterest from '../../assets/static/icon/pinterestfooter@2x.png'
import artist from '../../assets/static/images/artist.png'
import purchase from '../../assets/static/images/purchase.png'
import print from '../../assets/static/images/print.png'
import Carrousel from '../Carousel'
const facebook = "https://www.facebook.com/"
const whats = "https://wa.me/573103122081"
const insta = "https://www.instagram.com/"
const pinte = "https://pinterest.com"

const phrase = '"Laborum voluptas harum laudantium nihil error soluta odio ea quod fugiat mollitia vel dignissimos corrupti quasi tenetur!"'

const AboutUs = ({texts, clients, banners}) => {

    // console.log('clients', clients)

    const title=texts.about_title;
    const subtitle=texts.about_subtitle;
    const title_cliente=texts.about_cliente;    
    const title_redes=texts.about_title_redes
    const info_artista=texts.about_info_artista
    const info_compra=texts.about_info_compra
    const info_imprimir=texts.about_info_imprimir

    const distintions = [
        {id:1,name:"Asesoria",img:asesoria,},
        {id:2,name:"Compra",img:compra,},
        {id:3,name:"Garantía",img:garantia,},
        {id:4,name:"Original",img:original,},
        {id:5,name:"talento",img:talento,},
    ]
     

    const navigate=(id)=>{
        const direction={
            'artist':'/infoArtist',
            'purchase':'/infoPurchase',
            'print':'/infoPrint',
        }
        window.location=direction[id] ? direction[id] : '/'
    }

    const handleClick = (dir)=>{
        let direction = 0
        direction = {
            'left':-100,
            'right':100
        }
        if (dir) {
            document.getElementById('client_cont').scrollLeft += direction[dir];
        }
    }

    return (
        <div className='aboutUs'>
            <Carrousel contentCarousel={banners}/>
            <div className='aboutUs__distintion'>
                {distintions.map((item)=>
                <div className='aboutUs__distintion-item' key={item.id}>
                    <img src={item.img} alt='Reconocimiento'/>
                    <label>{item.name}</label>
                </div>
                )}
            </div>
            <div className='aboutUs__client'>
                <h2>{title_cliente} </h2>
                <div className='aboutUs__client-cont'>
                    <img className='aboutUs__client-arrow' src={left} alt='left' onClick={()=>handleClick('left')}/>
                        <div className='aboutUs__client-show' id='client_cont'>
                            {clients.map((client,i)=>
                                <div className='aboutUs__client-item' key={"client.id"+i}>
                                    <img src={client?.file.url} alt='Cliente'/>
                                    <span style={{fontWeight:'bold'}} >{client?.location}, </span>
                                    <span style={{fontWeight:'bold'}} >{client?.name}, </span>
                                    <span style={{fontWeight:'bold'}} >{client?.artist.name}, </span>
                                    <span style={{fontWeight:'bold'}} >{client?.photo.name}, </span>
                                    <span>{client?.description}</span>
                                </div>
                            )}
                        </div>
                    <img className='aboutUs__client-arrow' src={right} alt='right' onClick={()=>handleClick('right')}/>
                </div>
            </div>
            <div className='aboutUs__info'>
                <h1>{title_redes} </h1>
                <div className='aboutUs__info-social'>
                    {insta&&<a href={insta} target="_blank"><img src={logoInsta} alt='Social'/></a>}
                    {/* {facebook&&<a href={facebook} target="_blank"><img src={logoFacebook} alt='Social'/></a>} */}
                    {whats&&<a href={whats} target="_blank"><img src={logoWhats} alt='Social'/></a>}
                    {/* {pinte&&<a href={pinte} target="_blank"><img src={logoPinterest} alt='Social'/></a>} */}
                </div>
                <div className='aboutUs__info-other'>
                    <div>
                        <img src={artist} alt='Artista' onClick={()=>navigate('artist')}/>
                        <h2>{info_artista}</h2>
                    </div>
                    <div>
                        <img src={purchase} alt='Compra' onClick={()=>navigate('purchase')}/>
                        <h2>{info_compra} </h2>
                    </div>
                    {/* <div>
                        <img src={print} alt='Imprimir' onClick={()=>navigate('print')}/>
                        <h2>{info_imprimir}</h2>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default AboutUs
