import React from 'react'
import Title from '../Title'
import MaterialCard from '../MaterialCard'
import fotografico from '../../assets/static/images/fotografico.png'
import fineArt from '../../assets/static/images/fine_art.png'
import chromaluxe from '../../assets/static/images/chromaluxe.png'
import lienzo from '../../assets/static/images/lienzo.png'
import marcoSec from '../../assets/static/images/marcoSection.png'
import canvasSec from '../../assets/static/images/canvas-section.png'
import acrilicoSec from '../../assets/static/images/acrilico-section.png'
import papelSec from '../../assets/static/images/papel-section.png'
import retablosSec from '../../assets/static/images/retablos-section.png'
import materialSec from '../../assets/static/images/material-section.png'

const MainSectionMaterials = ({materials}) => {
    const navigate = (id) => {
        window.location = `/materials/${id}`
    }

    return (
        <div className="sec-materials">
            <Title title="MATERIALES" listener={()=>navigate('back')}/>
            <div className="materials__pres">
                <div className="materials__background">
                    <div className="materials__img--pos">
                        <a href='/materials/PAPELES'>                            
                            <span>Fotográfico</span>                            
                            <img src={fotografico} alt="foto" />   
                        </a>
                        <a href='/materials/PAPELES'>
                            <span>Fine Art</span>
                            <img src={fineArt} alt="fineArt"/>
                        </a>
                        <a href='/materials/CHROMALUXE'>
                            <span id='material_ch'>Chromaluxe</span>
                            <img src={chromaluxe} alt="chromaluxe"/>                           
                        </a>
                        <a href='/materials/LIENZO'>                        
                            <span>Lienzo</span>                            
                            <img  src={lienzo} alt="lienzo" />                            
                        </a>
                    </div>
                </div>
            </div>
            <div className="materials__cont">
                {materials.map((material) =>
                    <MaterialCard type={material.name} text={material.description} img={material.file.url} key={"material" + material.id} main={true} borderColor={'#D1D2D7'} listener={()=> navigate(material?.name)} />
                )}
            </div>   
        </div>
    )
}

export default MainSectionMaterials
