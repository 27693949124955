import React, { useEffect, useState } from 'react'
import edit from '../../assets/static/buttons/editarlapiz@2x.png'
import AddAdressModal from '../AddAddresModal'
import Delete from "../../assets/static/buttons/eliminar@2x.png";
import UpdateAdressModal from '../UpdateAddressModal';

const UserAccountData = ({ userAccount, addresses }) => {
    const [addressesData, setAddressesData] = useState(addresses)
    const [tel1, setTel1] = useState(false)
    const [tel2, setTel2] = useState(false)
    const [showAddressModal, setShowAddressModal] = useState(false)
    const [showUpdateAddressModal, setShowUpdateAddressModal] = useState(false)
    const [showUpdateAddressData, setShowUpdateAddressData] = useState({})
    const [passwordForm, setPasswordForm] = useState(null)

    // console.log('userAccount', userAccount)
    // console.log('addressesData', addressesData)

    useEffect(() => {
        fetch("/update_password", {
            method: 'GET', 
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then((res) => {
          return res.text();
        })
        .then((data) => {
          let wrapper = document.createElement("div");
          wrapper.innerHTML = data;
          let formulario = wrapper.querySelector(".newPassword_form");
          if (formulario) {       
            setPasswordForm(formulario.outerHTML);
          }
        });
        }, [])
    

    const edit_phone = async (phone, select, e ) => {
        e.preventDefault()   
        let response = await fetch(`/update_phone`, {
            method: 'PUT', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone: phone, select:select}),
        })
        response = await response.json()
        window.location.reload();
        // console.log('PHONE',response)
    }

    const delete_address = async (address) => {
        var result = confirm("Estas seguro de borrar esta direccion?");
        if(result){
            let response = await fetch(`/delete_address`, {
                method: 'DELETE', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: address.id}),
            })
            response = await response.json()
            if (response?.success===true){
                setAddressesData(response?.addresses)
                alert("Dirección eliminada correctamente")
            } else{
                alert("error al guardar direccion")
            }
        }
    }

    const edit_address = (obj) => {
        let address = {...obj}
        setShowUpdateAddressData(address)
        setShowUpdateAddressModal(true)
    }

    useEffect(() => {
        if(tel1) {
            document.getElementById('phone').focus()
        }
        if(tel2) {
            document.getElementById('phone2').focus()
        }
    }, [tel1, tel2])
    
    const editTelForm = (input) => {
        if(input === 1){
            setTel1(true)
            document.getElementById('phone').focus()
            if(tel2) {setTel2(false)}
        }else if(input === 2){
            setTel2(true)
            document.getElementById('phone2').focus()
            if(tel1) {setTel1(false)}
        }
    }

    return (
        <div>     
            <div className='userAccountData'>
                <legend>Datos de cuenta</legend>
                <div className='userAccountData__main' >
                    <div className='userAccountData__inputCont' >
                        <label>Nombre</label>
                        <input className='userAccountData__input' placeholder={userAccount.name+" "+userAccount.last_name} type='text' disabled />
                    </div>
                    <div className='userAccountData__inputCont' >
                        <label>Cédula</label>
                        <input className='userAccountData__input'  placeholder={userAccount.identification}  type='text' disabled/>
                    </div>
                    <div className='userAccountData__inputCont' >
                        <label>Correo</label>
                        <input className='userAccountData__input'  placeholder={userAccount.email} type='email' disabled />
                    </div>
                    <form id="form_phone1" className='userAccountData__inputCont' onSubmit={(e) => edit_phone(document.getElementById('phone').value, 1, e)}>
                        <label>Celular</label>
                        <input className={`userAccountData__input ${tel1?"focus":""}`} placeholder={userAccount.mobile_phone} type='text' id='phone' pattern="[0-9]{10}" onInvalid={()=>alert("Tu numero celular debe tener 10 caracteres")} disabled={!tel1} required/>
                        {!tel1 ? <img className='userAccountData__edit' src={edit} alt="Modificar" onClick={()=>editTelForm(1)} />
                        :
                        <SaveIcon/>}
                    </form>
                    <form id="form_phone2" className='userAccountData__inputCont' onSubmit={(e) => edit_phone(document.getElementById('phone2').value, 2, e)}>
                        <label>Celular 2</label>
                        <input className={`userAccountData__input ${tel2?"focus":""}`} placeholder={userAccount.mobile_phone2} type='text' id='phone2' pattern="[0-9]{10}" onInvalid={()=>alert("Tu numero celular debe tener 10 caracteres")} disabled={!tel2}/>
                        {!tel2 ? <img className='userAccountData__edit' src={edit} alt="Modificar" onClick={()=>editTelForm(2)} />
                        :
                        <SaveIcon/>}
                    </form>
                </div>
                
                <legend>Direcciones de entrega</legend>
                <div className='userAccountData__address'>
                    
                    {addressesData?.map((address, i) => {
                        return(
                        <div key={i + 1} className='userAccountData__address-input'>
                            <label> {address.name} - {address.mobile_phone}</label>
                            <label> {address.city} - {address.state}</label>
                            <label> {address.address}</label>
                            <div className='userAccountDataAdress__actions'>
                                <img src={edit} alt="Modificar" onClick={() => edit_address(address)} />
                                <img className='userAccountData__actions_eliminar' src={Delete} alt="Eliminar" onClick={() => delete_address(address)} />
                            </div>
                        </div>
                        
                        )
                    })}
            
                </div>
                <div className='userAccountData__Agre'>
                    <a onClick={() => setShowAddressModal(!showAddressModal)} style={{ cursor: 'pointer' }}>Agregar Domicilio</a>
                </div>


            </div>
            <legend className='legend_pass'>Cambiar Contraseña</legend>
            <form className="newPassword_form" id="edit_user_1" action="/user/update_password" acceptCharset="UTF-8" method="post"> 
                <div className='userAccountData__pass' dangerouslySetInnerHTML={{ __html: passwordForm }} />         
            </form>

            {/* modals */}
            {showAddressModal&&<AddAdressModal showAddressModal={showAddressModal} listener={(showAddressModal)=>{setShowAddressModal(showAddressModal)}} setUpdateData={(data)=>setAddressesData(data)} />}
            {showUpdateAddressModal&&<UpdateAdressModal data={showUpdateAddressData} listener={(showEditModal)=>{setShowUpdateAddressModal(showEditModal)}} showEditModal={showUpdateAddressModal} setUpdateData={(data)=>setAddressesData(data)}/>}
        </div>
    )
}

const SaveIcon = ({color = "#000000"}) => (
    <button type='submit' className='userAccountData__edit'>
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 416c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"/></svg>
    </button>
)

export default UserAccountData
