import React from 'react'
import SeeButton from '../../SeeButton'

const NotFound = () => {
  return (
    <div className="notFound">
      <div className="notFound__container">
        <h1>😞</h1>
        <h2>¡Ups! Página no encontrada.</h2>
        <p>Lo sentimos pero la página que buscas no existe.</p>
        <SeeButton listener={()=>window.location="/"} textBtn="Volver al home" style={{border:'2px solid black'}}/>
      </div>
    </div>
  )
}

export default NotFound