import React, { useEffect, useRef, useState } from 'react'
import SeeButton from './SeeButton'
import cerrar from '../assets/static/buttons/boton cerrar.png'
import { cities } from '../data/cities'

const UpdateAdressModal = ({showEditModal, data, listener, setUpdateData}) => {
    const [cityValue, setCityValue] = useState(data.city)

    const ModalBgRef = useRef({})
    
    const updateAddress = async (e) => {
        e.preventDefault()
        let form = document.forms['update-address']
        let response = await fetch(`/update_address`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: data.id,  address:form.address.value, name:form.name.value, mobile_phone:form.mobile_phone.value, city:form.city.value, state:form.state.value})
        })
        response = await response.json()
        // console.log(response)
        if (response?.success===true){
            setUpdateData(response?.addresses)
            hideModal()
            alert("Dirección actulizada correctamente")
        } else{
            alert("error al guardar direccion")
        }
    }
    

    const hideModal = () => {
        document.body.style.overflow='visible'
        listener(!showEditModal)     
    }

    useEffect(() => {
        if (showEditModal) {
            document.body.style.overflow='hidden'
        }else{
            document.body.style.overflow='visible'
        }
    }, [showEditModal])

    useEffect(() => {
        const clickEvent=()=>{
            document.body.style.overflow='visible'
            listener(!showEditModal)
        }
        const keyEsc=(e)=>{
            if (e.key=="Escape") {
                document.body.style.overflow='visible'
                listener(!showEditModal)
            }
        }
        if (showEditModal) {
            ModalBgRef.current.addEventListener('click',clickEvent)
            document.addEventListener('keyup', keyEsc)
        }
        return () => {
            if(!showEditModal){
                ModalBgRef.current.removeEventListener("click", clickEvent)
                document.removeEventListener('keyup',keyEsc)}
        }
    }, [showEditModal])

    return (
        <div className='container-addres' hidden={showEditModal?false:true}>
            <div className='addressModal__backGround' ref={ModalBgRef}>
            </div>
            <div className="container-addres-modal">
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'relative' }}>
                    <h2 className='title-add-addres'>MODIFICAR DOMICILIO</h2>
                    <img src={cerrar} className='img-close-modal-addres' onClick={() => hideModal()} />
                </div>
                <div style={{overflow:'auto'}}>
                    <form className='form-addres' name='update-address' onSubmit={(e)=>updateAddress(e)}>
                    <label className='form-add-addres-input' >Nombre
                            <input className='inputs-form-addres-general' name='name' minLength="3" defaultValue={data.name}  required></input>               
                        </label>
                        <label className='form-add-addres-input' >Celular
                            <input className='inputs-form-addres-general-der' pattern="[0-9]{10}" name='mobile_phone' defaultValue={data.mobile_phone} required></input>
                        </label>
                        <label className='form-add-addres-input' >Ciudad
                            <select className='inputs-form-addres-general-der' name="city" defaultValue={data.city} onChange={(e)=>setCityValue(e.target.value)}>
                                <option value=""></option>
                                {cities.map((city, i)=><option key={"city"+i} value={city.city}>{`${city.city} (${city.state})`}</option>)}
                            </select>
                        </label>
                        <label className='form-add-addres-input' >Departamento
                            <input className='inputs-form-addres-general-der' name='state' defaultValue={cities.find(city => city.city === cityValue )?.state} disabled></input>
                        </label>
                        <label className='form-add-addres-input' >Direccion
                            <input className='inputs-form-addres-general-der' name='address' minLength="10" defaultValue={data.address} required></input>
                        </label>
                        <SeeButton type={'submit'} textBtn={'Modificar'} style={{border:'2px solid black', width:'12.8rem'}}/>
                    </form>                  
                </div>
            </div>
        </div>
    )
}

export default UpdateAdressModal