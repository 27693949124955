import React from 'react'
import Title from './Title'
import Wsp from '../assets/static/icon/whatscontacto@2x.png'
import Correo from '../assets/static/icon/correocontacto@2x.png'
import Horario from '../assets/static/icon/horariocontacto@2x.png'
import Ubicacion from '../assets/static/icon/ubicacioncontacto@2x.png'
const country = ['COLOMBIA','ARGENTINA','VENEZUELA','BRAZIL',]
const city = ['BOGOTÁ','CALI','MEDELLÍN',]

export default function Contactanos({texts}) {
    const title=texts.contact_title;
    const telefono=texts.contact_tel;
    const subtitle=texts.contact_subtitle;
    const horario=texts.contact_horario;
    const email=texts.contact_email;
    const description= texts.contact_description;
    const address =texts.contact_address

    const icons = [
        { img: Wsp, info: telefono },
        { img: Correo, info: email },
        { img: Horario, info: horario },
        { img: Ubicacion, info: address  },
    ]

    
    const create_contact = async (obj) => {
        let contactForm = document.getElementById('contactForm');
        let response = await fetch('/create_contact', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: obj['name'], email: obj['email'], country: obj['country'], city: obj['city'], 
            mobile_phone: obj['mobile_phone'], message: obj['message'], }),
        })
        response = await response.json(),
        
        // console.log('contact',response)
        alert(response.msg)
        if (response.success) {contactForm.reset()}
    }

   
          
    const submitContact = (e) => {
        e.preventDefault()
        // console.log('e', e)
        let obj={}
        obj['name'] = e?.target[0].value
        obj['email'] = e?.target[1].value
        obj['country'] = e?.target[2].value
        obj['city'] = e?.target[3].value
        obj['mobile_phone'] = e?.target[4].value
        obj['message'] = e?.target[5].value
        // console.log(obj);
        create_contact(obj)
      }

    return (
        <div>
            <Title title={title} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p className='contact__paragraph'>
                    {description} 
                </p>
            </div>
            <div className='contact__icons'>
                {icons.map( (icon,i)  =>
                    <div style={{ width: '130px', textAlign: 'center' }} key={'icons'+i} >
                        <img src={icon.img} />
                        <label>{icon.info}</label>
                    </div>
                )}
            </div>

                <h2 style={{ textAlign: 'center' }}>{subtitle}</h2>
            <div className='contact__form'>
                    <div >
                        <form id="contactForm" className='contact__form-cont'
                         onSubmit={(e)=>submitContact(e)} >
                            <label>Tu nombre</label>
                            <input name='nombre' className='contact__input-form' type='text' required></input>
                            <label>Tu email</label>
                            <input className='contact__input-form' name='email' pattern='[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z]{2,}' onInvalid={()=>alert('Tu correo debe seguir el formato: correo@correo.com')} type='text' required></input>
                            <label>País</label>
                            <select name='pais' className='contact__input-form'>
                                {country.map((country,i)=>
                                <option key={"country"+i}>{country}</option>
                                )}
                            </select>
                            <label>Ciudad</label>
                            <select name='ciudad' className='contact__input-form'>
                                {city.map((city,i)=>
                                <option key={"city"+i}>{city}</option>
                                )}
                            </select>
                            <label>Número de contacto</label>
                            <input name='numContancto' className='contact__input-form' type='text' required></input>
                            <label>Mensaje</label>
                            <textarea name='mensaje' className='contact__input-form-msg' type='text' required></textarea>
                            <div className='contact__terms' style={{ textAlign: 'center', marginTop: '2%' }}>
                                <input name='terminos' type='checkbox' required />
                                <span>He leído y acepto los </span>
                                <a href='#'>Terminos y condiciones</a>
                            </div>
                            <button className='contact__button-contact' type="submit">
                                ENVIAR
                            </button>
                        </form>
                    </div>
            </div>
        </div>
    )
}
