import React from 'react'
import check from '../../assets/static/graphics/check@2x.png'

const UserAccountPurchase = ({purchase}) => {
    let date = new Date(purchase.created_at)
    let items = purchase.products
    // console.log('purchase', purchase)
    return (
        <>
            <div className='userAccountPurchase'>
                {items?.map((product, i) => {
                    let productData = JSON.parse(product||"{}")
                    // console.log('productData :>> ', productData);
                    return(
                    <div key = {`product ${i}`} className='contenedor'>
                        <img className='userAccountPurchase__img' src={productData?.photo?.file.url}></img><div className='userAccountPurchase__info'>
                            <h3>linea: {productData?.photo?.line}</h3>
                            <span style={{ fontWeight: 'bold' }}>{productData?.photo?.name}, </span>
                            <span>{productData?.photo?.description}, </span>
                            <span>{productData?.size?.dimensions}, </span>
                            <span>marco {productData?.frame?.name}, </span>
                            <span>material {productData?.material?.name},  </span>
                            <span>cantidad {productData?.quantity}.</span>
                            <h3>precio (iva incluido): ${((productData?.photo?.base_price + productData?.frame?.price + productData?.material?.price + productData?.package?.price + productData?.size?.price * productData?.quantity)*1.19).toLocaleString()}</h3>                   
                        </div>
                        <div></div>
                        <div className='userAccountPurchase__payment'>
                                <img src={check} alt='Estado de pago' />
                                <div >
                                    <h3>Detalle de pago:</h3>
                                    <span>{date.toLocaleString()}</span>
                                    { purchase?.status && <h5 style={{ fontWeight: 'bold' }}>Respuesta: {purchase.status}</h5>}
                                    <h5 style={{ fontWeight: 'bold' }}>Código de autorización: {purchase.ref_number}</h5>
                                </div>
                            </div>
                    </div>
                )}
                )}
            </div>
            
        </>
    )
}

export default UserAccountPurchase
