import React from 'react'
import Title from '../Title'
import img from '../../assets/static/images/purchase.png'
const text = 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur laboriosam eveniet nobis quas facilis quis quam obcaecati consequatur necessitatibus porro voluptates itaque at perferendis, nulla ducimus corrupti impedit repellendus a.'




const InfoPurchase = ({texts}) => {
    const purchase_button=texts.purchase_button;
    const purchase_title=texts.purchase_title;
    const purchase_steps=texts.purchase_steps;


    const list = purchase_steps.split('*')
    return (
        <div>
        <Title title={purchase_title} backTitle={true} listener={()=>history.back()}/>
        <div className='infoPurchase'>
            <div className='infoPurchase__cont'>
                <div className='infoPurchase__img'>
                    <img src={img}/>
                    <button onClick={
                        (e)=>{ 
                        e.preventDefault();
                        window.location='/line/2'}} 
                        >{purchase_button}
                    </button>
                </div>
                <div className='infoPurchase__list'>
                    <ol>
                        {list.map((item,i)=>
                        <li key={'item'+i}>{item}</li>)}
                    </ol>
                </div>
            </div>
        </div>
    </div>
    )
}

export default InfoPurchase
