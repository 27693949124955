import React from 'react'

const SectionInfo = ({reverse, title, photo, text, subtitle, color, listener}) => {
    const bgColor = (number) => {
        switch (number) {
            case 1: return "backGround--white"
            case 2: return "backGround--purple"
            default: return "backGround--green"
        }
    }
    return (
        <section className={`sectionRoom ${bgColor(color)}`}>
            <div className={`sectionRoom__cont${reverse ? " reverse_cont" : ""}`}>
                <div className={`sectionRoom__info${reverse ? " reverse_info" : ""}`}>
                    <h2 className="sectionRoom__title">
                        {title}
                    </h2>
                    {subtitle&&<span className="sectionRoom__subtitle">
                        {subtitle}
                    </span>}
                    <p className='sectionRoom__text'>
                    {text}
                    </p>
                </div>
                <img className='sectionRoom__img' src={photo.url} onClick={listener}/>
            </div>
        </section>
    )
}

export default SectionInfo
