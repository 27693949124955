import React from 'react'
import SeeButton from './SeeButton'

const SectionInfoMaterials = ({inverso, listener, material}) => {
    const image = material?.file?.url
    const showInverso = () => {
        let inv = inverso ? "inverso" : ""
        return inv
    }

    let color = inverso ? 'var(--backCream)' : 'var(--backWhiteCream)';

    return (
        <section className={`sectionInfo ${showInverso()}`} style={{ backgroundColor: color }}>
            <div className='sectionInfo__cont'>
                <div className={`sectionInfo__info ${showInverso()}`}>
                    <h2 className="sectionInfo__title">
                        {material?.name}
                    </h2>
                    <p className='sectionInfo__text'>
                        {material?.description}
                    </p>
                    <div className='sectionInfo__button'>
                        <SeeButton textBtn="Ver todos los materiales" listener={listener} /> 
                    </div>
                </div>
                <img className='sectionInfo__img' src={image} />
            </div>
        </section>
    )
}

export default SectionInfoMaterials
