import React, { useEffect, useState } from 'react'
import Title from '../Title'
import WishItem from './WishItem'
import leftButton from '../../assets/static/buttons/leftButton.png'
import rightButton from '../../assets/static/buttons/rightButton.png'
import Loading from '../Loading'

const SectionWishlist = ({ currentUser, title, products }) => {   
    const [productsData, setProductsData] = useState(products.reverse())
    const [page, setPage] = useState(1)
    const elements = title ? 12 : 6
    const pages = (Math.floor((productsData.length - 1) / elements) + 1)
    const [loading, setLoading] = useState(false)

    const deleteItem = async(wishItem, tag) => {
        
        if (!tag && !window.confirm("¿Deseas borrar el item?")) return
        setLoading(true)
        fetch(`/wishItem/${wishItem.id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(response => response.json())
          .then(data => {
                if (data.success) {
                    // set event for header counter
                    const eventWish = new CustomEvent("wishHandler", {detail:{ wishItems: data.items.length }})
                    window.dispatchEvent(eventWish)
                    if (tag) return
                    alert(data.msg)
                    setProductsData(data.items.reverse())
                    setLoading(false)
                } 
                else {
                    setLoading(false)
                    alert(data.msg)            
                }
            })
    }

    const sendToRoom = async(wishItem, tag) => {        
        let obj = {
            size: wishItem.size?.id,
            frame: wishItem.frame?.id,
            material: wishItem.material?.id,
            packing: wishItem.package?.id,
            photo: wishItem.photo?.id,
            room: wishItem.photo?.room_id
        }
        if (tag === "edit") {
            obj["edit"] = true
            obj["idItem"] = wishItem.id
        }
        if (obj) {
            let baseUrl = location.origin
            let url = new URL(`/room/${obj.room}`, baseUrl)
            let searchParams = url.searchParams
            Object.entries(obj).forEach((item)=>item[1]!== undefined && searchParams.set(item[0], item[1]))
            if (tag === "cart") { await deleteItem(wishItem, "purchase")}
            window.location = url.toString()
        }
    }

    const paginationWish = (products) => {
        if (products.length > elements) {
            let maxProduct = page * elements
            let minProduct = maxProduct - elements
            let showProducts = products.slice(minProduct, maxProduct)
            return showProducts
        }
        return products
    }
    const showProducts = paginationWish(productsData)

    return (
        <div className='sectionWishlist'>
            {title && <div className='sectionWishlist__title'>
                <Title title='WISHLIST' backTitle  listener={()=>history.back()}/>
            </div>}
            {showProducts.length > 0 ? 
            <div className="sectionWishList__container">
                <div className='sectionWishlist__cont'>
                    {showProducts.map((product, i) =>
                        <WishItem clickSendToEdit={()=>sendToRoom(product, "edit")} clickSendToCart={()=> sendToRoom(product, "cart")} clickDelete={()=> deleteItem(product)} product={product} key={product.id} />
                    )}
                </div>
                <div className='sectionWishlist__next-prev'>
                    <img src={leftButton} alt='Atras' style={{ visibility: `${page > 1 ? 'visible' : 'hidden'}` }} onClick={() => { setPage(page - 1); document.documentElement.scrollTop = 0 }} />
                    <span>{`Página ${page} de ${pages}`}</span>
                    <img src={rightButton} alt='Siguente' style={{ visibility: `${page < pages && page !== pages ? 'visible' : 'hidden'}` }} onClick={() => { setPage(page + 1); document.documentElement.scrollTop = 0 }} />
                </div>
            </div>
            :
            <div className="sectionWishList__message">No tienes elementos en el wish list</div>}
            {loading && <Loading showLoading={loading}/>}
        </div>
    )
}

export default SectionWishlist
