import React, { useState, useEffect } from 'react'
import Title from '../Title'
import CartItem from './CartItem'
import PaymentInformation from '../PaymentInformation'
import ModalButton from '../ModalButton'
import Loading from '../Loading'

const SectionShoppingCart = ({ authenticity_token, currentUser, addresses, products }) => {
    const [items, setItems] = useState(null);
    const [lastLineId, setLastLineId] = useState(null)
    const [outStock, setOutStock] = useState(null)
    const [totalCostItems, setTotalCostItems] = useState(0);
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        if (items){
            let lastItem = items[items.length-1]
            // get total cost items
            let acum = 0
            items?.map((w) => acum = acum + ((w.frame.price + w.material.price + w.package.price + w.size.price) * (w.quantity || 1)))
            
            // get room from last item
            setLastLineId(lastItem?.photo.line_id)
            setTotalCostItems(acum)
        }
    }, [items])

    const filterProducts = (items) => {
        const filteredProducts = items.filter((item)=>item.photo.stock > 0)
        setItems(filteredProducts.reverse())
    
        const filteredOutStockProducts = items.filter((item)=>item.photo.stock <= 0)
        setOutStock(filteredOutStockProducts.reverse())   
    }

    useEffect(()=>{
        filterProducts(products)
    },[])

    const deleteItem = async(wishItem, button ) => {
        if (button && !window.confirm("¿Deseas borrar el item?")) return
        setLoading(true)
        fetch(`/cartItem/${wishItem.id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
          }).then(response => response.json())
          .then(data => {
                if (data.success) {
                    if (button) alert(data.msg)
                    const eventCart = new CustomEvent("cartHandler", {detail:{ cartItems: data.items.length }})
				    window.dispatchEvent(eventCart)
                    filterProducts(data.items)
                } else alert(data.msg)            
                setLoading(false)
            })
    }

    const sendToWishlist = async(product) => {
        if (!window.confirm("¿Deseas agregar el item a la lista de deseos?")) return
        setLoading(true)

        let frame = product.frame.id
        let size = product.size.id
        let material = product.material.id
        let packing = product.package.id
        let photo = product.photo.id

        let obj = { frame, size, material, packing, photo }
        if (obj) {
            let response = await fetch('/wishItem', {
                method: 'POST', 
                headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
                },
                body: JSON.stringify(obj), 
            })
            response = await response.json()
            alert(response.msg)
            if (response.success) {
                const eventWish = new CustomEvent("wishHandler", {detail:{ wishItems: response.items.length }})
				window.dispatchEvent(eventWish)
            }
            setLoading(false)
        }
    }

    const editItem = async(item) => {
        let obj = {
            idItem: item.id,
            cart: true,
            size: item.size?.id,
            frame: item.frame?.id,
            material: item.material?.id,
            packing: item.package?.id,
            photo: item.photo?.id,
            room: item.photo?.room_id,
        }
        if (obj) {
            let baseUrl = location.origin
            let url = new URL(`/room/${obj.room}`, baseUrl)
            let searchParams = url.searchParams
            Object.entries(obj).forEach((item)=>item[1]!== undefined && searchParams.set(item[0], item[1]))
            window.location = url.toString()
        }
    }

    const updateAmount = (obj) => {
        let tempProducts = [...items]

        const findIndex = tempProducts.findIndex(product => product.reference === obj.reference)
        tempProducts.splice(findIndex, 1, obj)

        setItems(tempProducts)
    }

    return (
        <div className="sectionShoppingCart">
            <Title title="CARRITO"  backTitle={true} listener={()=>history.back()}/>
            <div className="shoppingCard__cont">
                {items?.length === 0 ?
                <div className="sectionShoppingCart__button">
                    <ModalButton textBtn={"Adquirir fotografías"} listener={()=>{window.location = `/line/1`}}/>
                </div>
                :
                <div className="shoppingCard__items">
                    {items && items.map((product, i) =>                   
                        <CartItem {...{product, editItem, updateAmount}} clickDelete={()=> deleteItem(product, true)} key={`ìtem${i}`} clickWishList={(product)=>sendToWishlist(product)}/>
                    )}

                    {outStock && outStock.map((product, i) =>
                        <CartItem {...{product, editItem, updateAmount}} clickDelete={()=> deleteItem(product)} key={`ìtem${i}`} clickWishList={(product)=>sendToWishlist(product)}/>
                    )}
                    <div className="sectionShoppingCart__button">
                        <ModalButton textBtn={"Adquirir más fotografías"} listener={()=>{window.location = `/line/${lastLineId}`}}/>
                    </div>
                </div>}
                <div className="sectionShoppingCart__payment">
                    <PaymentInformation {...{ items, authenticity_token, currentUser, addresses,  totalCostItems}} />
                </div>
            </div>
            {loading && <Loading showLoading={loading}/>}
        </div>
    )
}

export default SectionShoppingCart
