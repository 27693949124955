import React from 'react'
import Title from '../Title'
import MaterialCards from '../MaterialCard'

const SectionMaterialInfo = ({ info, subMaterials = []}) => {
    const name = info?.name
    const title1 = info?.title_info1
    const text1 = info?.text_info1
    const img1 = info?.img_info1?.url
    const title2 = info?.title_info2
    const text2 = info?.text_info2
    const img2 = info?.img_info2?.url

    const navigate=(dir)=>{
        const direction={
            'back':'/materials'
        }
        window.location=direction[dir] ? direction[dir] : '/'
    }
    
    return (
        <>
            <Title title={name} backTitle={true} listener={()=>navigate('back')}/>
            <div className='sectionFrames'>
                <div className='sectionFrames__first-intro'>
                    {title1 && <h2>{title1}</h2>}
                    {text1 && <p>{text1}</p>}
                    {img1 && <img src={img1} alt='info1'/>}
                </div>
                <div className='sectionFrames__second-intro'>
                    {title2 && <h2>{title2}</h2>}
                    {text2 && <p>{text2}</p>}
                    {img2 && <img src={img2} alt='info2'/>}
                </div>
                <div className='sectionFrames__content'>
                    <div className="sectionFrames__materials">
                    {subMaterials.map((item, i) =>
                        <MaterialCards id={item.id} img={item.file.url} text={item.description} type={item.name} borderColor={'#D6E1E6'} key={"material"+i}/>
                    )}
                    </div>         
                </div>
            </div>
        </>
    )
}

export default SectionMaterialInfo
