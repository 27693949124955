import React from 'react'
import Title from '../Title'
import img from '../../assets/static/images/artist.png'

const text = 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur laboriosam eveniet nobis quas facilis quis quam obcaecati consequatur necessitatibus porro voluptates itaque at perferendis, nulla ducimus corrupti impedit repellendus a.'

const InfoArtist = ({texts} ) => {


    const artist_button=texts.artist_button;
    const artist_title=texts.artist_title;
    const artist_steps=texts.artist_steps;

    const list = artist_steps.split('*')
    // become_1
    return (
        <div>
            <Title title={artist_title} backTitle={true} listener={()=>history.back()}/>
            <div className='infoArtist'>
                <div className='infoArtist__cont'>
                    <div className='infoArtist__img'>
                        <img src={img}/>
                        <button onClick={
                        (e)=>{ 
                        e.preventDefault();
                        window.location='contact'}} 
                        >{artist_button}</button>
                    </div>
                    <div className='infoArtist__list'>
                        <ol>
                            {list.map((item,i)=>
                            <li key={"item"+i}>{item}</li>)}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoArtist
