import React, { useEffect, useRef, useState } from 'react'
import SectionProductModal from '../SectionProductModal'
import WishItem from '../views/WishItem'


import minus from '../../assets/static/buttons/minus.png'
import plus from '../../assets/static/buttons/plus.png'
import sizeImg from '../../assets/static/images/size.png'
import wishList from '../../assets/static/buttons/perfilwish@2x.png'
import wishListActivo from '../../assets/static/buttons/perfilwishactivobtn@2x.png'
import compra from '../../assets/static/buttons/perfilcarrito@2x.png'
import compraActivo from '../../assets/static/buttons/perfilcarritoactivobtn@2x.png'
import guardar from '../../assets/static/buttons/guardar@2x.png'
import guardarActivo from '../../assets/static/buttons/guardaractivobtn@2x.png'

import CarouselDownMarket from  '../CarouselDownMarket'
import currencyFormat from '../utilities/currencyFormat'

const ShowProductPurchase = ({exclusive, likeAPro, photo, listenerWishList, listenerSendToCart, sizeInfo, materials, frames, packing, productPurchase, setProductPurchase, pricePurchase, editItem, editFromCart, editFromWish}) => {
    const [showExclusive] = useState(exclusive)
    const [showLikeAPro] = useState(likeAPro)
    const [accordionInfo, setAccordionInfo] = useState(0)
    const [selectedSize, setSelectedSize] = useState("")
    const [selectedMaterial, setSelectedMaterial] = useState("")
    const [selectedFrame, setSelectedFrame] = useState("")
    const [selectedPacking, setSelectedPacking] = useState("")
    const [modal, setModal] = useState(false)
    const modalDataRef = useRef({})
    const modalInfoRef = useRef({})
    const cartRef = useRef({})
    const compraRef=useRef({})

    const setData = ( i, tag)=>{
        // console.log('here :>> ', i, tag);
        let tempProductObj = {...productPurchase}
        let dataTags = { size: sizeInfo, material: materials, frame: frames, packing: packing}

        let selectedData = dataTags[tag]
        let selectedItem = selectedData[i]
        if(selectedItem){
            tempProductObj[tag] = selectedItem
            setProductPurchase(tempProductObj)
        }else{
            tempProductObj[tag] = {}
            setProductPurchase(tempProductObj)
        }
    }
    
    // console.log('editItem :>> ', editItem);
    // console.log('selectedFrame :>> ', selectedFrame);
    
    useEffect(() => {
      if (editItem.size){
        setSelectedSize(sizeInfo.findIndex(size=>size.id === editItem.size))
        setSelectedMaterial(materials.findIndex(material=>material.id === editItem.material))
        setSelectedFrame(frames.findIndex(frame=>frame.id === editItem.frame))
        setSelectedPacking(packing.findIndex(packing=>packing.id === editItem.packing))
      }
    }, [])
    
    
    useEffect(() => {
        let info = document.getElementsByClassName('accordeon-info')
        for (let i = 0; i < info.length; i++) {
            if(accordionInfo===(i+1)){
                let div = document.getElementsByClassName('accordeon-info')[i]
                div.style.height=`${div.scrollHeight}px`}
        }
    }, [accordionInfo])

    const showModal = (tag)=>{
        if (tag==='size') {
            modalDataRef.current=sizeInfo
            modalInfoRef.current=1
            setModal(true)
        }
        if (tag==='material') {
            modalDataRef.current=materials
            modalInfoRef.current=2
            setModal(true)
        }
        if (tag==='frame') {
            modalDataRef.current=frames
            modalInfoRef.current=3
            setModal(true)
        }
    }

    const handleButtonClick = (tag) => {
        if (tag === "wish") listenerWishList("purchase")
        if (tag === "updateWish") listenerWishList("updateWish")
        if (tag === "cart") {
            setSelectedSize("")
            setSelectedMaterial("")
            setSelectedFrame("")
            setSelectedPacking("")
            listenerSendToCart()
        }
        if (tag === "update") { listenerSendToCart() }
    }

    console.log('selectedFrame :>> ', selectedFrame);

    return (
    <div className="showProductPurchase">
        {modal&&<SectionProductModal {...{setData, modal, setModal, selectedSize, setSelectedSize, selectedMaterial, setSelectedMaterial, selectedFrame, setSelectedFrame}} info={modalInfoRef.current} dataModal={modalDataRef.current}/>}
        <div className="showProductPurchase__progress">
            <hr></hr>
            <div className="showProductPurchase__item">
                <div className="item-selected"></div>
                <span>Foto</span>
            </div>
            <div className="showProductPurchase__item">
                <div className={selectedSize!==""?"item-selected":""}></div>
                <span>Tamaño</span>
            </div>
            {!showExclusive&&
            <div className="showProductPurchase__item">
                <div className={selectedMaterial!==""?"item-selected":""}></div>
                <span>Materiales</span>
            </div>}
            <div className="showProductPurchase__item">
                <div className={selectedFrame!==""?"item-selected":""}></div>
                <span>Marco</span>
            </div>
            <div className="showProductPurchase__item">
                <div className={selectedPacking!==""?"item-selected":""}></div>
                <span>Empaque</span>
            </div>
        </div>
        <div className="showProductPurchase__product">
            <div className="showProductPurchase__info">
                <img src={photo.file.url} alt={photo.name}/>
                <div>
                    <span><strong style={{fontWeight:"bold"}}>{photo.name}:  </strong> {photo.description} </span>
                    <span>{photo.info}</span>
                </div>
            </div>
            <div className="showProductPurchase__carac">
                <div className="showProductPurchase__accordeon">
                    <div className={`accordeon-title${accordionInfo===1?"-selected":""}`} onClick={()=>{accordionInfo===1?setAccordionInfo(0):setAccordionInfo(1)}}><div className='accordeon-title-text'><p>Tamaño</p>{selectedSize!==""&&<CheckIcon color={accordionInfo===1?"#ffffff":"#000000"}/>}</div>
                    <img src={accordionInfo===1?minus:plus}/></div>
                    <div className="accordeon-info"  style={accordionInfo!==1?{height:'0'}:{}}>
                        {/* <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. </span> */}
                        <div className="accordeon-sizes">
                            <CarouselDownMarket photos={sizeInfo} idSelected={selectedSize} listener={()=>{showModal('size')}}  srcImg={sizeImg} accordion tamaño/>
                        </div>
                    </div>
                    <div className={`accordeon-title${accordionInfo===2?"-selected":""}`} onClick={()=>{accordionInfo===2?setAccordionInfo(0):setAccordionInfo(2)}} hidden={!showExclusive?false:true}><div className='accordeon-title-text'><p>Materiales</p>{selectedMaterial!==""&&<CheckIcon color={accordionInfo===2?"#ffffff":"#000000"}/>}</div><img src={accordionInfo===2?minus:plus}/>
                    </div>
                    <div className="accordeon-info" hidden={!showExclusive?false:true} style={accordionInfo!==2?{height:'0'}:{}}>
                        {/* <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. </span> */}
                        <div className="accordeon-material">
                            <CarouselDownMarket photos={materials} idSelected={selectedMaterial}  listener={()=>{showModal('material')}} accordion galeria={false} tamaño={false}/>
                        </div>
                    </div>
                    <div className={`accordeon-title${accordionInfo===3?"-selected":""}`} onClick={()=>{accordionInfo===3?setAccordionInfo(0):setAccordionInfo(3)}}><div className='accordeon-title-text'><p>Marco</p>{selectedFrame!==""&&<CheckIcon color={accordionInfo===3?"#ffffff":"#000000"}/>}</div><img src={accordionInfo===3?minus:plus}/></div>
                    <div className="accordeon-info" style={accordionInfo!==3?{height:'0'}:{}}>
                        {/* <span>Lorem ipsum dolor sit amet consectetur adipisicing elit.</span> */}
                        <div className="accordeon-frame">
                            {frames?.map((frame, i)=>
                            <div key={frame?.id} title={frame.name} onClick={()=>{showModal('frame')}} style={{cursor:"pointer"}}>
                                <input type="radio" name="marco" checked={selectedFrame===i?true:false} onChange={()=>{showModal('frame')}}/>
                                <label style={{cursor:"pointer"}}>{frame.name}</label>
                            </div>
                            )}
                        </div>
                    </div>
                    <div className={`accordeon-title${accordionInfo===4?"-selected":""}`} onClick={()=>{accordionInfo===4?setAccordionInfo(0):setAccordionInfo(4)}}><div className='accordeon-title-text'><p>Empaque</p>{selectedPacking!==""&&<CheckIcon color={accordionInfo===4?"#ffffff":"#000000"}/>}</div><img src={accordionInfo===4?minus:plus}/></div>
                    <div className="accordeon-info" style={accordionInfo!==4?{height:'0'}:{}}>
                        {/* <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. </span> */}
                        <div className="accordeon-package">
                        {packing.map((pack, i)=>
                            <div key={pack.id} title={pack.name} onClick={()=>{setSelectedPacking(i);setData(i, "packing")}} style={{cursor:"pointer"}}>
                                <input type="radio" name="pack" defaultChecked={selectedPacking===i?true:false}/>
                                <label style={{cursor:"pointer"}}>{pack.name} {currencyFormat(pack.price)}</label>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
                <div className="showProductPurchase__icons">
                    <div>
                        <span>Total: {currencyFormat(pricePurchase)}</span>
                    </div>
                    {!editFromCart && !editFromWish ? 
                    <>
                        {!showLikeAPro&&
                        <div onClick={()=>handleButtonClick("wish")}>
                            <img src={wishList} alt="wishlist"
                                onMouseOver={()=> cartRef.current.src= wishListActivo}
                                onMouseLeave={() => cartRef.current.src = wishList } ref={cartRef}
                                />
                            <span style={{marginTop: "10px"}}>Wish List</span>
                        </div>}
                        <div onClick={()=>handleButtonClick("cart")}>
                            <img src={compra} alt="compra" onMouseOver={()=> compraRef.current.src= compraActivo} onMouseLeave={()=> compraRef.current.src=compra} ref={compraRef}/>
                            <span style={{marginTop: "10px"}}>Mover Al Carrito</span>
                        </div>
                    </>
                    :
                    <div onClick={()=>handleButtonClick(`${editFromCart?"update":"updateWish"}`)}>
                        <img src={guardar} alt="compra" onMouseOver={()=> compraRef.current.src= guardarActivo} onMouseLeave={()=> compraRef.current.src = guardar} ref={compraRef}/>
                        <span style={{marginTop: "10px"}}>Guardar Cambios</span>
                    </div>}
                </div>
            </div>
        </div>
    </div>
    )
}

const CheckIcon = ({color = "#000000"}) => (
    <div style={{width: "20px", height: "20px"}}>
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} viewBox="0 0 512 512"><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
    </div>
)

export default ShowProductPurchase
