import React, { useEffect, useState } from 'react'
import Carrousel from '../Carousel'
import Title from '../Title'
import SectionRoom from '../SectionRoom'

const infoLine = ({rooms = [], line, contentCarousel}) => {
  const [viewMobile, setViewMobile] = useState(null)

  const navigate=(id)=> {
      window.location = "/room/" + id
  }

  let sortRooms = rooms.sort((a,b)=> a.id > b.id ? 1 : (a.id < b.id) ? -1 : 0 )

  useEffect(() => {
    const updateSize = ()=>{
      let width = window.innerWidth
      if (width >= 720 && viewMobile) {
        setViewMobile(false)
      }else if (width < 720 && !viewMobile) {
        setViewMobile(true)
      } 
    }
    if (viewMobile === null) updateSize()

    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  })
  
  return (
    <>
      <div className='infoLine' > 
        <Title title={line.name} alignLeft={true} listener={()=>navigate('home')}/>
        {!viewMobile && 
        <p className='infoLine__text'>
          {line.description}
        </p>}
      </div>
      {contentCarousel?.length > 0 && <Carrousel contentCarousel={contentCarousel} roomView/>}
      {viewMobile && 
      <div className="infoLine infoMobile">
        <p className='infoLine__text'>
        {line.description}
      </p>
      </div>}
      {rooms.length > 0 && <div className='infoLine__rooms'>
        <Title title={'SALAS'}/>
        {sortRooms.map((room, i) =>
            <SectionRoom key={"room"+room.id} reverse={ i % 2 === 0 ? false : true } color={i % 3} title={room.name} subtitle={room.title} photo = {room.file} text={room.description} listener={()=>navigate(room.id)}/>
        )}
      </div>}
    </>
  )
}

export default infoLine