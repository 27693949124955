import React from 'react'

const SectionFeatures = ({texts, lines}) => {

  const featuresTitle = texts?.features_title
  const featuresText = texts?.features_text

  const navigate=(id)=> {
    window.location = "/line/" + id
  }

  let sortLines = lines.sort((a,b)=> a.id > b.id ? 1 : (a.id < b.id) ? -1 : 0 )

  return (
    <div className="featured">
      <h1 className="featured__title" >{featuresTitle}</h1>
      <p>{featuresText}</p>
        <div className='featured__content'>
          {sortLines.map((line) =>
              <div className='featured__content-img' onClick={()=>navigate(line?.id)} key={"line"+line?.id}>
                  <img src={line?.image_index?.url} alt={line?.name}/>
                  <h2 className="label">
                    {line?.name}
                  </h2>
              </div>
          )}
        </div>
    </div>
  )
}

export default SectionFeatures
