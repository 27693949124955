
import React, { useEffect, useRef, useState } from 'react'
import ShowProduct from './product/ShowProduct'
import ShowProductPurchase from './product/ShowProductPurchase'
import CarrouselDown from './CarouselDownMarket'
import Modal from './Modal'
import ConfirmModal from './ConfirmModal'
import Loading from './Loading'

const SectionProduct = ({currentUser, view, title, photos, sizeInfo, materials, room, frames, packing, authenticity_token, wish_items = [], editItem}) => {
	const [photo, setPhoto] = useState(photos[0])
	const [screen, setScreen] = useState(view === 3 ? 'purchase':"picture")
	const [productPurchase, setProductPurchase] = useState({})
	const [pricePurchase, setPricePurchase] = useState()
	const [showModal, setShowModal] = useState(false)
	const [wishItems, setWishItems] = useState(wish_items)
	const [editFromCart, setEditFromCart] = useState(false)
	const [editFromWish, setEditFromWish] = useState(false)
	const [loading, setLoading] = useState(false)

	// console.log('editItem :>> ', editItem);
	// console.log('pricePurchase :>> ', pricePurchase);
	// console.log("photo", photo)
	console.log('productPurchase :>> ', productPurchase);
	// console.log('wish_items', wishItems)

	useEffect(() => {
		if (editItem?.photo){
			const photo = photos.find(item=>item.id === editItem?.photo)
			if (photo) setPhoto(photo)
			else alert("Fotografía no se encuentra disponible")
			if (editItem?.size) {
				let obj = {}
				obj["size"] = sizeInfo.find(size=>size.id === editItem.size)
				obj["material"] = materials.find(material=>material.id === editItem.material)
				obj["frame"] = frames.find(frame=>frame.id === editItem.frame)
				obj["packing"] = packing.find(packing=>packing.id === editItem.packing)
				setProductPurchase(obj)
			}
			if (editItem?.cart){setEditFromCart(true)}
			if (editItem?.edit){setEditFromWish(true)}
			setScreen("purchase")
		}
	}, [])

	useEffect(() => {
			if (photo) {
			// let photoPrice = photo.base_price
			let totalPrice = 0
			for (let property in productPurchase){
					let itemPrice = productPurchase[property]?.price || 0
					totalPrice += itemPrice
			}
			setPricePurchase(totalPrice);
	}}, [productPurchase, photo])

	const validateItems = () => {
			let frame = productPurchase?.frame?.id
			let size = productPurchase?.size?.id
			let material = productPurchase?.material?.id
			let packing = productPurchase?.packing?.id
			let photoId = photo?.id

			// validate items
			if (!photoId) return alert('Por favor seleccione la foto')
			if (!size) return alert('Por favor seleccione el tamaño')
			if (!material) return alert('Por favor seleccione el material')
			if (!frame) return alert('Por favor seleccione el marco')
			if (!packing) return alert('Por favor seleccione el empaque')

			let obj = { frame, size, material, packing, photo: photoId }

			return obj
	}

	const generateWishItem = async(tag) => {
		if(!currentUser) return window.location.href = "/users/sign_in"
		let obj = {}
		if (tag === "purchase" || tag === "updateWish") obj = validateItems()
		else obj = { photo: photo?.id }
		if (!obj) return
		if (tag !== "updateWish" && !window.confirm("¿Deseas agregar el item a la lista de deseos?")) return
		setLoading(true)
		if (tag === "updateWish") {obj["idItem"] = editItem?.idItem }
		if (obj){
			let response = await fetch(`/${tag==="updateWish"?"updateWishItem":"wishItem"}`, {
				method: 'POST', 
				headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
				},
				body: JSON.stringify(obj), 
			})
			response = await response.json()
			if(response.success){
				alert(response?.msg)
				if (tag === "updateWish") { return window.location = "/wishlist"}
				// set event for header counter
				const eventWish = new CustomEvent("wishHandler", {detail:{ wishItems: response.items.length }})
				window.dispatchEvent(eventWish)
				setWishItems(response.items)
				setLoading(false)
			}else{
				setLoading(false)
				alert(response.msg)
			}  
		}	
	}

	// console.log('editFromCart :>> ', editFromCart);
	
	const sendToCart = async() => {
		let obj = validateItems()
		if (editFromCart){obj["idItem"] = editItem?.idItem}
		if (obj) {
			setLoading(true)
			let response = await fetch(`/${editFromCart?"updateCartItem":"cartItem"}`, {
				method: 'POST', 
				headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
				},
				body: JSON.stringify(obj), 
			})
			response = await response.json()
			if (response.success) {
				// set event for header counter
				const eventCart = new CustomEvent("cartHandler", {detail:{ cartItems: response.itemsCount }})
				window.dispatchEvent(eventCart)
				if (!editFromCart) {
					setLoading(false)
					setShowModal(true)
				}
				else { window.location = "/cart" }
			}else{
				alert(response.msg)
				setLoading(false)
			}
		}
	}

	const continueShopping = () => {
		setShowModal(false)
		setProductPurchase({})
	}

	// console.log('photos', photos)

	if (!photos) return <div>Loading</div>

	return (
		<div className="sectionProduct">
			<div className="sectionProduct__title">
				<h1>{title}</h1>
			</div>
			{photos.length > 0 ? 
				<div className="sectionProduct__cont">
					{screen==="picture"?
						<ShowProduct currentUser={currentUser} photo={photo} room={room} setScreen={setScreen} listenerWishList={(tag)=>generateWishItem(tag)}/>
					:screen==="purchase"&&
						<ShowProductPurchase {...{ photo, sizeInfo, materials, frames, packing, productPurchase, setProductPurchase, pricePurchase, editItem, editFromCart, editFromWish }} listenerWishList={(tag)=>generateWishItem(tag)} listenerSendToCart={()=>sendToCart()} exclusive={view===1 ? true : false} likeAPro={view===3 ? true : false}/>}
					{photo && view !== 3 && //View 3 Like a pro
						<CarrouselDown idSelected={photo.id} photos={photos} listener={(photo)=> setPhoto(photo)} {...{wishItems}} galeria/>}
				</div>          
			:
				<span> <h3> Galeria vacia </h3> </span>
			}
			{showModal && 
			<Modal {...{showModal, setShowModal}}>
				<ConfirmModal cancel={()=>window.location = "/cart"} acept={()=>continueShopping()}/>
			</Modal>}
			{loading && <Loading showLoading={loading}/>}
		</div>
	)
}

export default SectionProduct
