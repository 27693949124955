import React from 'react'

const ActionButton = ({listener, style, textBtn}) => {
    return (
        <button className='actionButton' type='button' onClick={listener} style={{...style}}>
                {textBtn}
        </button>
    )
}

export default ActionButton
