import React, { useEffect, useRef, useState } from 'react'
import SeeButton from './SeeButton'
import cerrar from '../assets/static/buttons/boton cerrar.png'
import { cities } from '../data/cities'

const AddAdressModal = ({showAddressModal, listener, setUpdateData}) => {
    const [cityValue, setCityValue] = useState("")
    
    const ModalBgRef = useRef({})

    const hideModal = () => {
        document.body.style.overflow='visible'
        listener(!showAddressModal)
    }

    const createAddress = async (e) => {
        e.preventDefault()
        let form = document.forms['new-address']
        // console.log('ITEM',form)
        let response = await fetch(`/new_address`, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ address:form.address.value, name:form.name.value, mobile_phone:form.mobile_phone.value, city:form.city.value, state:form.state.value}),
        })
        response = await response.json()
        // console.log('prueba',response)
        if (response?.success===true){
            setUpdateData(response?.addresses)
            hideModal()
            alert("Dirección añadida correctamente")
        } else{
            alert("eror al guardar direccion")
        } 
    }
    
    useEffect(() => {
        if (showAddressModal) {
            document.body.style.overflow='hidden'
        }else{
            document.body.style.overflow='visible'
        }
    }, [showAddressModal])

    useEffect(() => {
        const clickEvent=()=>{
            document.body.style.overflow='visible'
            listener(!showAddressModal)
        }
        const keyEsc=(e)=>{
            if (e.key=="Escape") {
                document.body.style.overflow='visible'
                listener(!showAddressModal)
            }
        }
        if (showAddressModal) {
            ModalBgRef.current.addEventListener('click',clickEvent)
            document.addEventListener('keyup', keyEsc)
        }
        return () => {
            if(!showAddressModal){
                ModalBgRef.current.removeEventListener("click", clickEvent)
                document.removeEventListener('keyup',keyEsc)}
        }
    }, [showAddressModal])

    

    return (
        <div className='container-addres' hidden={showAddressModal?false:true}>
            <div className='addressModal__backGround' ref={ModalBgRef}>
            </div>
            <div className="container-addres-modal">
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'relative' }}>
                    <h2 className='title-add-addres'>AGREGAR DOMICILIO</h2>
                    <img src={cerrar} className='img-close-modal-addres' onClick={() => hideModal()} />
                </div>
                <div style={{overflow:'auto'}}>
                    <form className='form-addres' name='new-address' onSubmit={(e)=>createAddress(e)}>
                        <label className='form-add-addres-input' >Nombre
                            <input className='inputs-form-addres-general' name='name' minLength="3" required></input>               
                        </label>
                        <label className='form-add-addres-input' >Celular
                            <input className='inputs-form-addres-general-der' minLength="10" maxLength="10" name='mobile_phone'  required></input>
                        </label>
                        <label className='form-add-addres-input' >Ciudad
                            <select className='inputs-form-addres-general-der' name="city" onChange={(e)=>setCityValue(e.target.value)}>
                                <option value=""></option>
                                {cities.map((city, i)=><option key={"city"+i} value={city.city}>{`${city.city} (${city.state})`}</option>)}
                            </select>
                        </label>
                        <label className='form-add-addres-input' >Departamento
                            <input className='inputs-form-addres-general-der' name='state' defaultValue={cities.find(city => city.city === cityValue )?.state} disabled></input>
                        </label>
                        <label className='form-add-addres-input' >Direccion
                            <input className='inputs-form-addres-general-der' name='address' minLength="10" required></input>
                        </label>
                        
                        <SeeButton type={'submit'} textBtn={'Agregar'} style={{border:'2px solid black', width:'12.8rem'}}/>
                    </form>                  
                </div>
            </div>
        </div>
    )
}

export default AddAdressModal