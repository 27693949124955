import React, { useEffect, useRef, useState } from "react";

import LogoVisa from "../assets/static/icon/visa-logo.png";
import LogoAmerican from "../assets/static/icon/american-express.png";
import LogoMaster from "../assets/static/icon/mastercard-logo.png";
import api from "./api";
import AddAdressModal from './AddAddresModal'
import currencyFormat from "./utilities/currencyFormat";
import SeeButton from "./SeeButton";
import Loading from "./Loading";

const PaymentInformation = ({ items, currentUser, addresses, totalCostItems, authenticity_token }) => {
  const [tab, setTab] = useState("Check");
  const [answer, setAnswer] = useState(null);
  const [addressesData, setAddressesData] = useState(addresses);
  const [selectedAddress, setSelectedAddress] = useState({})
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [shippings, setShippings] = useState([])
  const [selectedShipping, setSelectedShipping] = useState({})
  const [costDelivery, setCostDelivery] = useState(0)
  const [loading, setLoading] = useState(false)
  
  const tempAddress = {id: 0, name:"Recoger en punto.", city:"Medellín", state: "Antioquia", address: "Carrera 1 # 64-61" }
  const tempShipping = {id: 0, city:"Medellín", description:"Recoger en punto.", supplier:"", cost:0}
  
  useEffect(() => {
    document.documentElement.scrollTop = 0
  }, [tab])
  
  // console.log("items", items)
  // console.log('totalCostItems', totalCostItems)

  const handleAddressSelect = async (address, point) => {
    let shippingOp = document.getElementById("shipping_radio")
    const city = address.city

    if (point) {
      shippingOp.checked = true
      setSelectedShipping(tempShipping)
      setSelectedAddress(address)
      if (shippings.length > 0) setShippings([])
      return shippingOp.scrollIntoView({behavior: "smooth", block: "center"});
    }

    let response = await fetch(`/shippings/${city}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
    })
    response = await response.json()
    if (response?.shippings.length > 0){
      let count = response?.shippings.length
      // console.log('response?.shippings :>> ', response?.shippings);
      alert(`Disponemos de ${count} opci${count>1?"ones":"ón"} de envio para esta ciudad.`)
      setShippings(response?.shippings)
      if (selectedShipping?.id) setSelectedShipping(tempShipping)
    } 
    else {
      shippingOp.checked = true
      setSelectedShipping(tempShipping)
      alert("No hay opciones de envio para esta ciudad.")
      if (shippings.length > 0) setShippings([])
    }
    setSelectedAddress(address)
    shippingOp.scrollIntoView({behavior: "smooth", block: "center"});
  }
  
  const handleSelectSend= (e) => {
    const shippingItem = JSON.parse(e.target.value)
    setCostDelivery(parseFloat(shippingItem.cost))
    setSelectedShipping(shippingItem)
  }
  
  const checkStock = async () => {
    const addressCont = document.querySelector(".paymentInformation__addressCont")
    const shippingCont = document.querySelector(".paymentInformation__addressCont")
    
    if(selectedAddress?.id===undefined) {
      alert("Selecciona la dirección de entrega antes de continuar")
      return addressCont.scrollIntoView({behavior: "smooth", block: "end"});
    } 
    if(selectedShipping?.id===undefined) {
      alert("Selecciona el tipo de envío antes de continuar")
      return shippingCont.scrollIntoView({behavior: "smooth", block: "start"});
    } 
    setLoading(true)

    const obj = {}

    items?.map((product) => (
      obj[product.photo.id] ? obj[product.photo.id] += product.quantity
      :
      obj[product.photo.id] = product.quantity
    ))
  
    api.verifyAvailable({ ids: obj }, (response) => {

      // console.log('response', response)
      if (response.success) {
      // console.log("CON STOCK");
      // console.log("productsPay", items)

      let deliveryInfo = {...selectedShipping}
      deliveryInfo["address"] = selectedAddress

      fetch('/transaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ items_cost: totalCostItems, delivery_info: deliveryInfo, cost_delivery: costDelivery, products: items, authenticity_token: authenticity_token }),
      }).then(response => response.json())
        .then(data => {
          // console.log('data', data)
          setAnswer(data)
          setTab("Payment")
          setLoading(false)
          alert(data?.msg)
        })
      }
      else {
        alert(response.msg)
        setLoading(false)
      }
    })
  }

  const handleRedimer = (e) => {
    if(e.keyCode === 13){
      e.preventDefault()
      alert("El código de descuento no es válido");
      e.target.value = ""
    }
  }
  
  return (
    
    <div className="paymentInformation__cont">
  
      <div className="paymentInformation__tabs">
        <div className={ tab === "Check" ? "paymentInformation__tabs--selected" : "" } onClick={() => {setTab("Check")}} >
          Check Out
        </div>
        <div className={ tab === "Account" ? "paymentInformation__tabs--selected" : "" } onClick={() => setTab("Account")} >
          Datos
        </div>
        <div className={ tab === "Payment" ? "paymentInformation__tabs--selected" : "" } onClick={() => {setTab("Payment")}} >
          Pagos
        </div>
      </div>

      <div className="paymentInformation__account">
        {tab === "Check" && (
          <div className="paymentInformation__check">
            <legend>¡Gracias por tú compra!</legend>
            <span style={{ alignSelf: "center", marginBottom: "5%" }}>
              En Artree estamos para ti
            </span>
            <h3 style={{ alignSelf: "center" }}>LISTA DE ORDEN</h3>
            <div className="paymentInformation__market">
            
              <h3>{`${items?.length} Producto(s)`}</h3>
              {/* {console.log('ESTE ES El ITEM',items )} */}
              
              {items?.map((product) => (
                // console.log(product),
                <div key={product?.id}>                          
                  <div>
                    {/* {console.log('ESTE ES EL PRODUCT',product )} */}
                    <span>{product?.photo.name}</span>
                    <span>{currencyFormat((product?.frame.price + product?.material.price + product?.package.price + product?.size.price) *
                    (product.quantity || 1))}</span>
                    
                  </div>
                </div>
              ))}
              <div>
                <span>Descuento</span>
                <span>-$0</span>
              </div>
              <div>
                <span>IVA (19%)</span>
                <span>{currencyFormat(totalCostItems * 0.19)}</span>
              </div>
              <div>
                <span>Envio</span>
                <span>{currencyFormat(costDelivery)}</span>
              </div>
              <div>
                <span>Subtotal</span>
                <span>{currencyFormat(totalCostItems+(totalCostItems * 0.19))}</span>
              </div>
            </div>
            <div className="paymentInformation__total">
              <span>Total</span>
              <span>{currencyFormat(totalCostItems + (totalCostItems * 0.19) + costDelivery )}</span>
            </div>
            <div className="paymentInformation__code">
              <label>Código de Descuento</label>
              <input className="paymentInformation__codeInput" type="text" onKeyDown={(e)=>handleRedimer(e)}></input>
            </div>
            <button type="button" onClick={() => setTab("Account")}>
              Continuar
            </button>
          </div>
        )}
        {tab === "Account" && (
          <div className="paymentInformation__account__data" >
            {currentUser &&
              <div className="paymentInformation__account-logout">
                <h3 className="account-logout-title">¡Gracias por comprar con nosotros!</h3>
                <p className="account-logout-subtitle">A continuación encontraras los datos de facturación</p>

                <div className="paymentInformation__account-logout-data " >
                  <p >Nombre: <span>{currentUser.name} {currentUser.last_name}</span> </p>
                  <p>Documento:<span> {currentUser.identification}</span> </p>
                  <p>Celular: <span>{currentUser.mobile_phone}</span> </p>

                  <div className="paymentInformation__addressCont">
                    <p>Selecciona la direccion a entregar</p>
                    <form >
                      <div className="paymentInformation__addressCont-radio">
                        <label>
                          <input type="radio" name="address" onChange={() => handleAddressSelect(tempAddress,true )} required></input>
                          {tempAddress.name} {tempAddress.address}, {tempAddress.city} - {tempAddress.state}
                        </label>
                      </div>
                      {addressesData.map((address, i) =>
                        <div className="paymentInformation__addressCont-radio" key={"address"+i}>
                          <label>
                            <input key={i} type="radio" name="address" checked={selectedAddress?.id === address.id} value={i} id={i} onChange={() => handleAddressSelect(address)} required></input>
                            {address.address}, {address.city} - {address.state}
                          </label>
                        </div>
                      )}
                    </form>
                    <div className="paymentInformation__address">
                      <input type="submit" onClick={() => setShowAddressModal(!showAddressModal)} style={{ cursor: 'pointer' }} value="Agregar Domicilio"/>
                    </div>
                  </div>
                </div>
                <div className="paymentInformation__shipping">
                  <p>Selecciona el tipo de envio</p>
                  <span>Valores por unidad de producto</span>
                  <form>
                      <div className="paymentInformation__shipping-radio">
                        <input id="shipping_radio" type="radio" name="send" checked={ selectedShipping.id === tempShipping.id } value={JSON.stringify(tempShipping)} onChange = {(e) => handleSelectSend(e)} required></input>
                        <label htmlFor={'shipping_radio'}>{currencyFormat(0)}</label>
                        <label htmlFor={'shipping_radio'}>Recoger en punto o Acordar con Artree</label>
                      </div>
                    {shippings.map((shipping, i) => 
                      <div className="paymentInformation__shipping-radio" key={"shipping"+i}>
                        <input key={"shipping"+i} checked={ selectedShipping.id === shipping.id } type="radio" name="send" value={JSON.stringify(shipping)} id={'radiosend_'+i} onChange = {(e) => handleSelectSend(e)} required></input>
                        <label htmlFor={'radiosend_'+i} value={i}>{currencyFormat( shipping.cost)}</label>
                        <label htmlFor={'radiosend_'+i}>{shipping.city+". "} {shipping.supplier} - {shipping.description}</label>
                      </div>
                    )}
                  </form>
                </div>
              </div>}

              <div className="paymentInformation__payment__total">
                <p>Total</p>
                <span>{currencyFormat(totalCostItems + (totalCostItems * 0.19) + costDelivery )}</span>
              </div>

              <div className="paymentInformation__payment__btnContinue">
                <input type="submit" onClick={() =>{checkStock()}} style={{ cursor: 'pointer' }} value="Pagar"/>
              </div>
          </div>
        )}
        {tab === "Payment" && !answer ?
        <div className="payment__logout">
          <div className="paymentInformation__payment" style={{ alignItems: "center" }}>
            <legend>Métodos de pago</legend>
            <SeeButton textBtn={"Pagar con Wompi"} listener={() => {alert("Debes completar tu compra para continuar"); setTab("Account")} }/>
            <SeeButton textBtn={"Pagar con payU"} listener={() => {alert("Debes completar tu compra para continuar"); setTab("Account")} }/>
          </div>
          <div className="payment__targetsPurchase">
            <div className='payment__targetsPurchase-logos'>
              <div className='payment__targetsPurchase-logo'>
                  <img src={LogoVisa} alt='Visa'/>
              </div>
              <div className='payment__targetsPurchase-logo'>
                  <img src={LogoAmerican} alt='American Express'/>
              </div>
              <div className='payment__targetsPurchase-logo'>
                  <img src={LogoMaster} alt='MasterCard'/>
              </div>
            </div>                    
          </div>
        </div>
        :                    
        tab === "Payment" && answer &&
        <div className="payment__logout">
          <div className="paymentInformation__payment" style={{ alignItems: "center" }}>
            <legend>Métodos de pago</legend>
            {/* form pago wompi */}
            <form action="https://checkout.wompi.co/p/" method="GET" id="wompi">
              <input type="hidden" name="public-key" value={answer.env.public_key} />
              <input type="hidden" name="currency" value="COP" />
              <input type="hidden" name="amount-in-cents" value={answer.transaction.total_cost * 100} />
              <input type="hidden" name="reference" value={answer.transaction.ref_number} />
              <input type="hidden" name="signature:integrity" value={answer.transaction.wompi_sign} />
              <input type="hidden" name="redirect-url" value={answer.env.wompi_redirect} />
              <input type="hidden" name="tax-in-cents:vat" value={answer.transaction.iva_tax * 100} />
              <input type="hidden" name="tax-in-cents:consumption" value={answer.transaction.consumption_tax * 100} />
              <input type="hidden" name="customer-data:email" value={currentUser.email} />
              <input type="hidden" name="customer-data:full-name" value={`${currentUser.name}` +` `+ `${currentUser.last_name}`} />
              <input type="hidden" name="customer-data:phone-number" value={currentUser.mobile_phone} />
              <input type="hidden" name="customer-data:legal-id" value={currentUser.identification} />

              <input type="hidden" name="shipping-address:country" value="CO" />
              <input type="hidden" name="shipping-address:city" value={selectedAddress?.city}  placeholder="Ciudad"/>
              <input type="hidden" name="shipping-address:phone-number" value={selectedAddress?.mobile_phone} placeholder="telefono" />
              <input type="hidden" name="shipping-address:region" value={selectedAddress?.state} placeholder="region" />
              <input type="hidden" name="shipping-address:address-line-1" value={selectedAddress?.address} />

              <button className="paymentInformation__paymentButton" type="submit" >Pagar con Wompi</button>
            </form>

            {/* form pago de payu */}
            <form method="post" action={answer.env.payu_url}>
              <input name="merchantId"      type="hidden" value={answer.env.merchantID}  />
              <input name="accountId"       type="hidden" value={answer.env.account_id}/>
              <input name="description"     type="hidden" value="TRUE" />
              <input name="referenceCode"   type="hidden" value={answer.transaction.ref_number}/>
              <input name="amount"          type="hidden" value={answer.transaction.total_cost}  />
              <input name="tax"             type="hidden" value={answer.transaction.iva_tax} />
              <input name="taxReturnBase"   type="hidden" value={answer.transaction.total_cost - answer.transaction.iva_tax - answer.transaction.consumption_tax}/>
              <input name="currency"        type="hidden" value="COP"/>
              <input name="signature"       type="hidden" value={answer.transaction.payu_sign} />
              <input name="test"            type="hidden" value={answer.env.test}/> 
              <input name="responseUrl"     type="hidden" value={answer.env.payu_response}/> 
              <input name="confirmationUrl" type="hidden" value={answer.env.payu_confirmation}/>
              <input name="buyerEmail"      type="hidden" value={currentUser.email}/>
              <input name="extra1"          type="hidden" value={currentUser.identification}/>
              <input name="shippingCountry" type="hidden" value="CO" />
              <input name="shippingCity"    type="hidden" value={selectedAddress?.city}/>
              <input name="shippingAddress" type="hidden" value={selectedAddress?.address}/>

              <input className="paymentInformation__paymentButton" name="Submit" type="submit" value="Pagar con payU"/>
            </form>
          </div>

          <div className="payment__targetsPurchase">
            <div className='payment__targetsPurchase-logos'>
              <div className='payment__targetsPurchase-logo'>
                  <img src={LogoVisa} alt='Visa'/>
              </div>
              <div className='payment__targetsPurchase-logo'>
                  <img src={LogoAmerican} alt='American Express'/>
              </div>
              <div className='payment__targetsPurchase-logo'>
                  <img src={LogoMaster} alt='MasterCard'/>
              </div>
            </div>                    
          </div>
        </div>
        }
      </div>
      {showAddressModal&&<AddAdressModal showAddressModal={showAddressModal} listener={(showAddressModal)=>{setShowAddressModal(showAddressModal)}} setUpdateData={(data)=>setAddressesData(data)}/>}
      {loading&&<Loading showLoading={loading}/>}
    </div>
  );
};

export default PaymentInformation;
