import React from 'react'
import Carousel from './Carousel'
import SectionInfoArtist from './SectionInfoArtist'
import SectionFeatures from './SectionFeatured'
import SectionInfoMaterials from './SectionInfoMaterials'

const content = ({contentCarousel, texts, lines, artist, material}) => {

  const navigate = (section) => {
    const redirect = {
      'artist': '/artist',
      'infoMaterials': '/materials'
    }
    window.location = redirect[section] ? redirect[section] : '/'
  }

  // console.log('material :>> ', material);
  
  return (
    <>
      <Carousel contentCarousel={contentCarousel}/>
      <SectionFeatures texts={texts} lines={lines} />
      <SectionInfoArtist inverso={true} listener={() => navigate('artist')} artist={artist} />
      <SectionInfoMaterials listener={() => navigate('infoMaterials')} material={material}/>
    </>
  )
}

export default content
